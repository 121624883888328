import React, { Component } from "react";
import "./GimsReportFilter.css";
import { Accordion, Card, Button, Form, Col, Image } from "react-bootstrap";
import EndpointConstant from "../../../constants/EndpointConstant";
import utils from "../../../utils";
import MultiSelect from "@khanacademy/react-multi-select";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PopupModels from "../../PopupModels/PopupModels";
import MessageConstant from "../../../constants/MessageConstant";
import RoleConstant from "../../../constants/RoleConstant";
import moment from "moment";
import PropTypes from "prop-types";

/**
 * A class that filter gims report
 **/
class GimsReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconName: "icon-drop-up",
      searchresult: [],
      selected: [],
      dataSet: [],
      selectedId: [],
      startDate: "",
      endDate: "",
      datePopup: false,
      errorMsg: "",
      toggle: false,
    };
    this.iconChange = this.iconChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.handleMyreport = this.handleMyreport.bind(this);
    this.toggleState = this.toggleState.bind(this);
  }
  /*
   * Toggling functionality for changing icon
   * in search header while showing and hiding search option
   */
  iconChange() {
    if (this.state.iconName === "icon-drop-up") {
      this.setState({ iconName: "icon-drop-down" });
    } else {
      this.setState({ iconName: "icon-drop-up" });
    }
  }

  /*
   * Reset all the search filter and
   * call search api to get all the data
   */
  handleOnClear(event) {
    event.preventDefault();
    this.setState(
      {
        // iconName: 'icon-drop-up',
        selected: [],
        startDate: "",
        endDate: "",
      },
      () => {
        this.toggleState(event, 1);
      }
    );
  }

  /*
   * Toggle MyReport and All reports
   */
  toggleState(event, isClear = 0) {
    const currentToggleStatus = isClear
      ? this.state.toggle
      : !this.state.toggle;
    this.setState({
      toggle: currentToggleStatus,
    });
    this.handleMyreport(event, !currentToggleStatus);
  }

  /*
   * Handle my report for admin role
   */
  handleMyreport(event, toggle) {
    event.preventDefault();
    const options = [];
    if (this.state.dataSet) {
      this.state.dataSet.map((dataset) => {
        return options.push({
          id: dataset.id,
          label: dataset.datasetName && dataset.datasetName.split(".")[0],
          value: dataset.datasetName && dataset.datasetName.split(".")[0],
        });
      });
    }
    if (toggle) {
      this.getGimsMiReport(options, [], 0);
    } else {
      let currentUserId = utils.getSessionItem("userId");
      let dupDataset = this.state.dataSet
        .filter((datasetDetails) => datasetDetails.createdBy === currentUserId)
        .map((val) => val.datasetName && val.datasetName.split(".")[0]);
      dupDataset = this.state.selected.length
        ? dupDataset.filter((val) =>
            this.state.selected.some((val1) => val1 === val)
          )
        : dupDataset;
      this.getGimsMiReport(options, dupDataset, 1);

      // if (dupDataset.length > 0) {
      //     filteredData.datasetId = dupDataset;
      // } else {
      //     filteredData.datasetId = ['myReport'];
      // }
    }
  }

  /*
   * Getting all the dataset dropdown option
   * by calling dataset API for search section
   */
  getDataSet() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.GET_DATASET_LIST,
      data,
      function (result) {
        //console.log("get dataset", result);
        self.setState({
          dataSet: result.datasets,
        });
      }
    );
  }

  /*
   * Close the date picker popup
   */
  handleDateClose() {
    this.setState({ datePopup: false });
  }
  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }
  /*
   * Call Search api to get all the filtered data
   * to show in charts
   */
  handleSearch(event) {
    event.preventDefault();
    // const options = [];
    // let dupDataset = [];
    // if (this.state.dataSet) {
    //     this.state.dataSet.map((dataset) => {
    //         return options.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.datasetName && dataset.datasetName.split('.')[0] });
    //     });
    // }

    // if (this.state.selected.some(val=> val === "myReport")) {
    //     let currentUserId = utils.getSessionItem("userId");
    //     dupDataset = this.state.dataSet.filter(datasetDetails =>datasetDetails.createdBy === currentUserId ).map(val => val.id);
    //  }

    // if(this.state.selected.some(val => val === "userReport")) {
    //     let data = {};
    //     let self = this;
    //     utils.showLoader();
    //     utils.makeRequest(this, EndpointConstant.GET_USER_LIST, data, function (result) {
    //         utils.hideLoader();
    //         let users = result.users.filter(user => user.role === RoleConstant.Admin);
    //         let data = self.state.dataSet.filter(datasetDetails => {
    //            return  users.some(user => user.id === datasetDetails.createdBy);
    //         }).map(val => val.id);
    //         data = [...dupDataset, ...data];
    //         dupDataset = data.filter((val, i) => data.indexOf(val) === i);
    //         self.getGimsMiReport(options, dupDataset);
    //     });
    // } else {
    this.handleMyreport(event, !this.state.toggle);
    // }
  }

  /*
   * gims report
   */

  getGimsMiReport(options, dupDataset, isMyReport) {
    let filteredData = {};
    let selectedId = [];
    // let selectedDataset = options.filter(datasetDetails =>{
    //     return  this.state.selected.some(data => data === datasetDetails.value);
    //  }).map(val => val.id);
    // //  console.log('dataser', selectedDataset, dupDataset, this.state.selected, options)
    // let data = [...selectedDataset, ...dupDataset];
    // dupDataset = data.filter((val, i) => data.indexOf(val) === i);
    // // console.log('dup', dupDataset)
    // // var selectedId = options.filter((data) => dupDataset.indexOf(data.id) >= 0).map((d) => d.id);
    // var selectedId = dupDataset.length ? dupDataset : ['noData'];
    // var selectedId = options.filter((data) => this.state.selected.indexOf(data.value) >= 0).map((d) => d.id);
    if (isMyReport) {
      // console.log('dupDataset.length', dupDataset.length)
      selectedId =
        dupDataset.length > 0
          ? options
              .filter((data) => dupDataset.indexOf(data.value) >= 0)
              .map((d) => d.id)
          : ["myReport"];
    } else {
      selectedId = options
        .filter((data) => this.state.selected.indexOf(data.value) >= 0)
        .map((d) => d.id);
    }
    var startDate = this.state.startDate
      ? moment(this.state.startDate, "MM/D/YYYY").startOf("day").unix()
      : "";
    var endDate = this.state.endDate
      ? moment(this.state.endDate, "MM/D/YYYY").endOf("day").unix()
      : "";
    // eslint-disable-next-line
    //console.log("startDate", moment.unix(startDate).format("MM/D/YYYY HH:MM A"));
    // eslint-disable-next-line
    //console.log("endDate", moment.unix(endDate).format("MM/D/YYYY HH:MM A"));

    if (startDate !== "" && endDate === "") {
      this.setState({
        datePopup: true,
        errorMsg: MessageConstant.END_DATE_EMPTY,
      });
    } else if (startDate === "" && endDate !== "") {
      this.setState({
        datePopup: true,
        errorMsg: MessageConstant.START_DATE_EMPTY,
      });
    } else if (startDate > endDate) {
      // alert('Invalid date');
      this.setState({
        datePopup: true,
        errorMsg: MessageConstant.INVALID_DATE,
      });
    } else {
      if (selectedId.length > 0) {
        filteredData.datasetId = selectedId;
      }
      if (startDate) {
        filteredData.startDate = startDate;
      }
      if (endDate) {
        filteredData.endDate = endDate;
      }
      //console.log("search data", filteredData, options);
      //console.log("this.state", this.state);
      this.props.getGimsFilteredReport(filteredData);
    }
  }

  /*
   * date validations
   */
  checkDateValidation(val) {
    var startDate = this.state.startDate
      ? moment(this.state.startDate, "MM/D/YYYY").startOf("day").unix()
      : "";
    var endDate = this.state.endDate
      ? moment(this.state.endDate, "MM/D/YYYY").endOf("day").unix()
      : "";

    if (
      startDate !== "" &&
      endDate !== "" &&
      (isNaN(startDate) || isNaN(endDate))
    ) {
      this.setState({
        datePopup: true,
        errorMsg: MessageConstant.INVALID_DATE,
      });
    } else if (startDate && endDate && startDate > endDate) {
      // alert('Invalid date');
      this.setState({
        datePopup: true,
        errorMsg: MessageConstant.INVALID_DATE,
        startDate: val == 1 ? "" : this.state.startDate,
        endDate: val == 2 ? "" : this.state.endDate,
      });
    }
  }

  /*
   * Hook to call the dataset after render
   */
  componentDidMount() {
    this.getDataSet();
  }

  /*
   * Rendering the view UI
   */
  render() {
    const { selected, dataSet } = this.state;
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate());
    const options = [];
    if (dataSet) {
      dataSet.forEach((dataset) => {
        if (dataset.status === "Complete") {
          return options.push({
            id: dataset.id,
            label: dataset.datasetName && dataset.datasetName.split(".")[0],
            value: dataset.datasetName && dataset.datasetName.split(".")[0],
          });
        }
      });
    }
    options.sort(this.compareDataset);
    // if (utils.getSessionItem("role") === RoleConstant.SuperAdmin) {
    //     options.unshift({id: 1, label: "My Report", value: "myReport"}, {id:11 , label: "User Report", value: "userReport"})
    // }
    return (
      <Accordion defaultActiveKey="0" className="cardPadding">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            onClick={this.iconChange}
            className="cardBodyStyle"
          >
            <span className="search-title">FILTER MICE REPORT</span>{" "}
            <Image
              src="/Images/ico-drop-up.png"
              className={this.state.iconName}
            ></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBodyStyle multiSelheight">
              <Form onSubmit={this.handleSearch} className="searchForm">
                <Form.Row>
                  <Col md={2}>
                    <Form.Label>Data Set</Form.Label>
                    <MultiSelect
                      options={options}
                      selected={selected}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={(selected) => {
                        //console.log("selected values", selected);
                        if (selected.length === 0) {
                          this.setState({ startDate: "", endDate: "" });
                        }
                        this.setState({ selected });
                        this.props.setDataForReportGeneration;
                        return null;
                      }}
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All",
                      }}
                    />
                  </Col>
                  {selected.length > 0 && (
                    <React.Fragment>
                      <Col md={2}>
                        <Form.Label>Start Date</Form.Label>
                        <Calendar
                          className="calender-styles calender-set"
                          placeholder="mm/dd/yyyy"
                          maxDate={maxDate}
                          value={this.state.startDate}
                          onChange={(e) =>
                            this.setState({ startDate: e.value }, () => {
                              this.checkDateValidation(1);
                            })
                          }
                          showIcon={true}
                        />
                      </Col>
                      <Col md={2}>
                        <Form.Label>End Date</Form.Label>
                        <Calendar
                          className="calender-styles calender-set"
                          placeholder="mm/dd/yyyy"
                          maxDate={maxDate}
                          value={this.state.endDate}
                          onChange={(e) =>
                            this.setState({ endDate: e.value }, () => {
                              this.checkDateValidation(2);
                            })
                          }
                          showIcon={true}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                  <Button
                    variant="secondary"
                    type="submit"
                    className="scorecardSearch button-color userSearch"
                  >
                    Search
                  </Button>
                  {/* {
                                        utils.getSessionItem("role") === RoleConstant.SuperAdmin && <Button variant="secondary" className='scorecardSearch button-color userSearch' onClick={this.handleMyreport}>My Report</Button>
                                    } */}
                  {(utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
                    utils.getSessionItem("role") === RoleConstant.SAdmin) && (
                    <div className="switch-field">
                      <Form.Check
                        inline
                        label="All Report"
                        type="radio"
                        name="switchToggle"
                        id="switch_left"
                        value="All Report"
                        checked={!this.state.toggle}
                        onChange={this.toggleState}
                        className="switch_left"
                      />
                      <Form.Check
                        inline
                        label="My Report"
                        type="radio"
                        name="switchToggle"
                        id="switch_right"
                        value="My Report"
                        checked={this.state.toggle}
                        onChange={this.toggleState}
                      />
                    </div>
                  )}
                  <Button
                    variant="secondary"
                    className="clear scorecardSearch userSearch"
                    onClick={this.handleOnClear}
                  >
                    Clear
                  </Button>
                  <Col></Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <PopupModels
          showPopup={this.state.datePopup}
          iconFlag="error"
          message={this.state.errorMsg}
          handleClosePopup={this.handleDateClose.bind(this)}
        />
      </Accordion>
    );
  }
}

GimsReportFilter.propTypes = {
  getGimsFilteredReport: PropTypes.func,
};

export default GimsReportFilter;
