import React, { Component } from "react";
import "./ScoreCard.css";
import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import utils from "./../../utils";
import RoleConstant from "./../../constants/RoleConstant";
import EndpointConstant from "./../../constants/EndpointConstant";
import MessageConstant from "./../../constants/MessageConstant";
// import RouterConstant from './../../constants/RouterConstant';
import CommonService from "../../commonService/CommonService";
import PopupModels from "./../PopupModels/PopupModels";
import PropTypes from "prop-types";
import ViewScoreCardDetails from "./ViewScoreCardDetails";
// import moment from 'moment';
import CommentHistory from "./../ErrorList/CommentHistory";
import MultiSelect from "@khanacademy/react-multi-select";

class EditScoreCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.details,
      questionaries:
        this.props.scoreCard === "new"
          ? []
          : this.props.questionaries.questionaries,
      questionsets: this.props.questionaries,
      selectedQuestionset:
        this.props.scoreCard === "new"
          ? ""
          : this.props.questionaries.questionsetid,
      scorecardUpdateModel: false,
      valid: false,
      error: false,
      countTrue: 0,
      countFalse: 0,
      percent: 0,
      scorecardId: "",
      showViewDetails: false,
      scorecardDetails: [],
      AEID_Q2_AE_Missed_QualityMonitorQC_Error: "",
      PC_Q1_IPRC_Match_QualityMonitorQC_Error: "",
      PCID_Q2_Missed_QualityMonitorQC_Error: "",
      AE_Q3_IPRC_QualityMonitorQC_Error: "",
      AE_Q2_HCP_QualityMonitorQC_Error: "",
      AE_Cap_IRPC_Match_QualityMonitorQC_Error: "",
      scorecardErrorModel: false,
      newcomment: "",
      comment: this.props.details.comments,
      showComment: false,
      question: [],
      isComplete: false,
      scorecardNoUserError: false,
      questionsetName: this.props.details.questionsetName
        ? this.props.details.questionsetName
        : "",
      questionsetid: this.props.details.questionsetid
        ? this.props.details.questionsetid
        : "",
      adminUsers: [],
      showAdminPopup: false,
      selectedAdmin: "",
      previousAssignedToAdminName:
        this.props.scoreCard === "edit"
          ? this.props.details.assignedToAdminName
          : "",
      previousAssignedToAdmin:
        this.props.scoreCard === "edit"
          ? this.props.details.assignedToAdmin
          : "",
      previousAssignedToName:
        this.props.scoreCard === "edit"
          ? this.props.details.assignedToName
          : "",
      previousAssignedTo:
        this.props.scoreCard === "edit" ? this.props.details.assignedTo : "",
      scorecardName:
        this.props.scoreCard === "edit" ? this.props.details.ScorecardName : "",
      scorecards: [],
      duplicateScorecard: false,
    };
    this.updateScorecard = this.updateScorecard.bind(this);
    this.completeScorecard = this.completeScorecard.bind(this);
    this.handleChangeYesNo = this.handleChangeYesNo.bind(this);
    this.handleUpdateClose = this.handleUpdateClose.bind(this);
    this.handleScorecardValidation = this.handleScorecardValidation.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.goBackTo = this.goBackTo.bind(this);

    this.showHistory = this.showHistory.bind(this);
    this.hideHistory = this.hideHistory.bind(this);
    this.routetoQM = this.routetoQM.bind(this);
    this.questionsetNameChanges = this.questionsetNameChanges.bind(this);
    this.getQuestionariesList = this.getQuestionariesList.bind(this);
    this.showAdminDetails = this.showAdminDetails.bind(this);
    this.handleAdminPopupClose = this.handleAdminPopupClose.bind(this);
    this.submitScorecard = this.submitScorecard.bind(this);
  }

  getQuestionariesList() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_QUESTIONARIE_LIST,
      data,
      function (result) {
        utils.hideLoader();
        if (result.error === false) {
          self.loading = false;
          self.setState({
            questionsets: result.Questionaries,
          });
        }
      }
    );
  }

  componentDidMount() {
    this.getQuestionariesList();
    this.getScoreCardList();
  }

  goBackTo() {
    if (this.props.scoreCard === "new") {
      // this.setState({scorecardDetails: this.props.details},()=>{this.setState({showViewDetails:true })})
      // this.props.hideEditScoreCard()
      this.props.hideDetails();
    } else {
      if (this.state.scorecardId !== "") {
        this.setState({ showViewDetails: true });
      } else {
        if (this.props.scoreCard === "edit") {
          this.props.hideDetails();
        } else {
          // this.props.hideDetails()
          this.props.hideEditScoreCard();
        }
      }
    }
  }
  isValidForm() {
    let data = {
      ...this.state,
    };
    if (
      utils.getSessionItem("role") === RoleConstant.QualityMonitor &&
      data.AE_Cap_IRPC_Match !== "" &&
      data.AE_Cap_IRPC_Match_QualityMonitorQC &&
      data.AEID_Q2_AE_Missed &&
      data.AEID_Q2_AE_Missed_QualityMonitorQC &&
      data.AE_Q2_HCP &&
      data.AE_Q2_HCP_QualityMonitorQC &&
      data.AE_Q3_IPRC &&
      data.AE_Q3_IPRC_QualityMonitorQC &&
      data.PCID_Q2_Missed &&
      data.PCID_Q2_Missed_QualityMonitorQC
    ) {
      this.setState({ valid: true });
    }
    // console.log("123"+data);
  }
  routetoQM() {
    let self = this;
    let data = this.handleRequestParam();
    data.type =
      this.state.stage === "otherAdmin"
        ? "OTHER_ADMIN_ASSIGN_TO_ADMIN"
        : "ADMIN_ASSIGN_TO_USER";
    data.createdBy = this.state.createdBy;
    data.questionsetid = this.state.questionsetid;
    data.questionsetName = this.state.questionsetName;
    data.assignedToAdmin = this.state.createdBy;
    data.assignedToAdminName = this.state.QMAssociate;
    data.assignedToRole = this.props.details.createdByRole;
    data.createdByRole = this.props.details.createdByRole;
    data.createdByScoreCard = this.props.details.createdBy;
    data.previousAssignedToAdminName = this.state.previousAssignedToAdminName;
    data.previousAssignedToAdmin = this.state.previousAssignedToAdmin;
    data.previousAssignedToName = this.state.previousAssignedToName;
    data.previousAssignedTo = this.state.previousAssignedTo;
    data.ScorecardName =
      this.state.ScorecardName === undefined
        ? ""
        : this.state.ScorecardName.trim();
    data.isAssignedToReviewer = false;
    // console.log('data', data, this.state);
    utils.makeRequest(
      this,
      EndpointConstant.UPDATE_SCORECARD,
      data,
      function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({
            scorecardUpdateModel: true,
            scorecardId: result.scorecardId,
          });
        } else if (result.error && result.errorCode === "S_USER_NOT_EXIST") {
          self.setState({ scorecardNoUserError: true });
        }
      }
    );

    // let self = this;
    // let data = {
    //   ...this.state
    // }
    // delete data.className;
    // delete data.createdAt;
    // delete data.modifiedAt;
    // delete data.valid;
    // if((this.props.scoreCard === 'editquality' || this.props.scoreCard==='edit') && (utils.getSessionItem("userId")!==this.props.details.createdBy && this.props.details.stage==='2') && this.state.qualityMonitorQCBy){
    //   data.type = 'QUALITYMONITOR_ASSIGN_TO_QUALITYMONITOR'
    //   data.assignedTo = RoleConstant.QualityMonitor
    //   data.comments=this.state.newcomment
    //   data.stage='1';
    //   utils.makeRequest(this, EndpointConstant.UPDATE_SCORECARD, data, function (result) {
    //     if (result.error === false || result.error === "false") {
    //       self.setState({
    //         scorecardUpdateModel: true,
    //         scorecardId: result.scorecardId
    //       });
    //     }

    //   })
    // }
    // else{
    //   this.setState({ scorecardErrorModel: true })
    // }
  }
  updateScorecard() {
    const isError = this.handleScorecardValidation();
    const isValid = this.handleValidation();
    // const isDuplicateScName = this.checkDuplicateScorecardName()
    let self = this;
    let data = this.handleRequestParam();
    let endpointName = "";
    if (isError || isValid) {
      this.setState({ scorecardErrorModel: true });
    } else {
      /* else if(isDuplicateScName) {
      this.setState({ duplicateScorecard: true })
    } 
    */
      data.type =
        utils.getSessionItem("role") === RoleConstant.Admin
          ? "USER_ASSIGN_TO_ADMIN"
          : "ADMIN_ASSIGN_TO_OTHER_ADMIN";
      data.questionsetid = this.state.questionsetid;
      data.questionsetName = this.state.questionsetName;
      data.assignedToAdmin = this.state.selectedAdmin;
      const assignedUser = this.state.adminUsers.filter(
        (val) => val.id == this.state.selectedAdmin
      );
      data.assignedToAdminName = assignedUser.length
        ? `${assignedUser[0].firstName} ${assignedUser[0].lastName}`
        : "";
      data.assignedToRole = assignedUser.length
        ? `${assignedUser[0].role}`
        : this.state.selectedAdmin;
      data.createdByRole = utils.getSessionItem("role");
      endpointName =
        this.props.scoreCard === "new"
          ? EndpointConstant.ADD_SCORE_CARD
          : EndpointConstant.UPDATE_SCORECARD;
      //  return;
      if (data.assignedToAdmin == "Reviewer") {
        data.isAssignedToReviewer = true;
      } else {
        data.isAssignedToReviewer = false;
      }
      if (this.props.scoreCard === "edit") {
        data.previousAssignedToAdminName =
          this.state.previousAssignedToAdminName;
        data.previousAssignedToAdmin = this.state.previousAssignedToAdmin;
        data.previousAssignedToName = this.state.previousAssignedToName;
        data.previousAssignedTo = this.state.previousAssignedTo;
      }
      // console.log("updatescorecard", data);
      utils.makeRequest(this, endpointName, data, function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({
            scorecardUpdateModel: true,
            scorecardId: result.scorecardId,
          });
        }
      });
    }
    //// console.log("updatescorecard", data);
  }

  completeScorecard() {
    let self = this;
    const isError = this.handleScorecardValidation();
    const isValid = this.handleValidation();
    // const isDuplicateScName = this.checkDuplicateScorecardName()
    if (isError || isValid) {
      this.setState({ scorecardErrorModel: true });
    } else {
      /* else if(isDuplicateScName) {
      this.setState({ duplicateScorecard: true })
    } */
      let data = this.handleRequestParam();
      // console.log("in 233:"+JSON.stringify(data))
      data.type = "ADMIN_ASSIGN_TO_ADMIN";
      // if(this.props.scoreCard === 'new') {
      data.questionsetid = this.state.questionsetid;
      data.questionsetName = this.state.questionsetName;
      data.assignedToAdmin = ""; // this.state.createdBy;
      data.assignedToAdminName = ""; // this.state.assignedToName;
      data.ScorecardName = this.state.ScorecardName;
      // }
      if (this.props.scoreCard === "edit") {
        data.previousAssignedToAdminName =
          this.state.previousAssignedToAdminName;
        data.previousAssignedToAdmin = this.state.previousAssignedToAdmin;
        data.previousAssignedToName = this.state.previousAssignedToName;
        data.previousAssignedTo = this.state.previousAssignedTo;
        data.ScorecardName = this.state.ScorecardName;
      }
      let endpoint =
        this.props.scoreCard === "new"
          ? EndpointConstant.ADD_SCORE_CARD
          : EndpointConstant.UPDATE_SCORECARD;
      // console.log("data in 245:"+JSON.stringify(data));
      // console.log("Data in 246:"+JSON.stringify(data.scorecards));
      utils.makeRequest(this, endpoint, data, function (result) {
        if (result.error === false || result.error === "false") {
          self.setState({
            scorecardUpdateModel: true,
            isComplete: true,
            scorecardId: result.scorecardId,
          });
        }
      });
    }
  }

  handleRequestParam() {
    // console.log('tessss', this.state)
    let data = {};
    if (this.props.scoreCard === "new") {
      data = {
        ...this.state,
      };
      // console.log("data in 269"+JSON.stringify(data));
      delete data.className;
      delete data.createdAt;
      delete data.modifiedAt;
      delete data.valid;
      delete data.id;
      delete data.agentNotes;
      delete data.questionsets;
      delete data.adminUsers;
      delete data.roleArr;
      delete data.userDetails;
      delete data.scorecards;
      delete data.scorecardQuestionDetails;
      delete data.AEID;
      delete data.AEID_Q2_AE_Error_Reconciliation;
      delete data.AEID_Q2_AE_Missed
      delete data.AEID_Q2_AE_Missed_Amgen
      delete data.AEID_Q2_AE_Missed_QualityMonitorQC
      delete data.AEID_Q2_AE_Missed_QualityMonitorQC_Error
      delete data.AE_Cap_IRPC_Match
      delete data.AE_Cap_IRPC_Match_Amgen
      delete data.AE_Cap_IRPC_Match_Error_Reconciliation
      delete data.AE_Cap_IRPC_Match_QualityMonitorQC
      delete data.AE_Cap_IRPC_Match_QualityMonitorQC_Error
      delete data.AE_Q2_HCP
      delete data.AE_Q2_HCP_Amgen
      delete data.AE_Q2_HCP_Error_Reconciliation
      delete data.AE_Q2_HCP_QualityMonitorQC
      delete data.AE_Q2_HCP_QualityMonitorQC_Error
      delete data.AE_Q3_IPRC
      delete data.AE_Q3_IPRC_Amgen
      delete data.AE_Q3_IPRC_Error_Reconciliation
      delete data.AE_Q3_IPRC_QualityMonitorQC
      delete data.AE_Q3_IPRC_QualityMonitorQC_Error
      delete data.IRPCContactName
      delete data.MIRDescription
      delete data.MIRID
      delete data.PCID_Q2_Missed
      delete data.PCID_Q2_Missed_Amgen
      delete data.PCID_Q2_Missed_Error_Reconciliation
      delete data.PCID_Q2_Missed_QualityMonitorQC
      delete data.PCID_Q2_Missed_QualityMonitorQC_Error
      delete data.PCMRID
      delete data.PC_Q1_IPRC_Match_Amgen
      delete data.PC_Q1_IPRC_Match_QualityMonitorQC_Error   
      data.interactionId = this.state.id;
      data.ScorecardName = this.state.ScorecardName;
    } else {
      data.questionaries = this.state.questionaries;
      data.id = this.state.id;
      data.interactionId = this.state.interactionId;
      data.datasetId = this.state.datasetId;
      data.product = this.state.product;
      data.questionStatus = this.state.questionStatus;
      data.ScorecardName = this.state.ScorecardName;
    }

    // data.cardName = this.state.cardName;
    data.comments = this.state.newcomment;
    this.setState({ valid: true });
    return data;
  }
  handleScorecardValidation() {
    const questions = this.state.questionaries;
    // const scorecardName = this.state.ScorecardName
    let UnansweredQns = questions.filter(
      (val) => val.answer === undefined || !val.answer.length
    );
    // let emptySCName = !scorecardName.length
    if (UnansweredQns.length) {
      for (let i = 0; i < UnansweredQns.length; i++) {
        if (document.getElementById(UnansweredQns[i].id)) {
          document.getElementById(UnansweredQns[i].id).classList.add("invalid");
        }
      }
    }
    return UnansweredQns.length === 0 ? false : true;
  }

  handleValidation() {
    if (!this.state.product || !this.state.questionStatus) {
      // alert(this.state.product.length);
      let esclass = document.getElementById("product").getAttribute("class");
      if (document.getElementById("product").value.trim() === "") {
        document
          .getElementById("product")
          .setAttribute("class", esclass + " invalid");
      }
      let esclass1 = document
        .getElementById("quesStatus")
        .getAttribute("class");
      if (document.getElementById("quesStatus").value.trim() === "") {
        document
          .getElementById("quesStatus")
          .setAttribute("class", esclass1 + " invalid");
      }

      return true;
    } else {
      return false;
    }
  }

  handleErrorClose() {
    this.setState({ scorecardErrorModel: false });
  }
  handleNoUserErrorClose() {
    this.setState({ scorecardNoUserError: false });
  }
  handleDuplicateSCNameError() {
    this.setState({ duplicateScorecard: false });
  }
  handleUpdateClose(event) {
    // event.preventDefault();
    let self = this;
    let data = {
      id: this.state.scorecardId,
    };
    // console.log("data", data);
    // this.setState({show:true})
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARD_DETAILS,
      data,
      function (result) {
        const scorecardDetails = result.scorecardDetails;
        self.loading = false;
        scorecardDetails[0].datasetName =
          self.props.scoreCard === "new"
            ? self.props.details.datasetNameView
            : self.props.details.datasetName;
        scorecardDetails[0].cardNew =
          self.props.scoreCard === "new"
            ? ""
            : self.props.details.ScorecardName;
        // console.log("editfnview", scorecardDetails);
        self.setState(
          {
            scorecardDetails: scorecardDetails,
            scorecardUpdateModel: false,
            isComplete: false,
          },
          () => {
            self.setState({ showViewDetails: true });
          }
        );
      }
    );
    // this.setState({
    //   scorecardUpdateModel: false
    // });
    // setTimeout(() => {
    //   if(this.props.scoreCard!=='new' && this.props.scoreCard!=='edit'){
    //   // this.goBackTo();
    //   window.location.reload(false)
    //   }else{
    //     utils.gotoPage(RouterConstant.SCORE_CARD_LINK)
    //   }
    // }, 500);
  }
  caliculation() {
    let count = {
      trueCount: 0,
      falseCount: 0,
    };
    //  let trueCount=0
    //  let falseCount=0
    if (this.state.AEID_Q2_AE_Error_Reconciliation === "True") {
      count.trueCount = ++count.trueCount;
    }
    if (this.state.AEID_Q2_AE_Error_Reconciliation === "False") {
      count.falseCount = ++count.falseCount;
    }
    if (this.state.AE_Cap_IRPC_Match_Error_Reconciliation === "True") {
      count.trueCount = ++count.trueCount;
    }
    if (this.state.AE_Cap_IRPC_Match_Error_Reconciliation === "False") {
      count.falseCount = ++count.falseCount;
    }
    if (this.state.AE_Q2_HCP_Error_Reconciliation === "True") {
      count.trueCount = ++count.trueCount;
    }
    if (this.state.AE_Q2_HCP_Error_Reconciliation === "False") {
      count.falseCount = ++count.falseCount;
    }
    if (this.state.AE_Q3_IPRC_Error_Reconciliation === "True") {
      count.trueCount = ++count.trueCount;
    }
    if (this.state.AE_Q3_IPRC_Error_Reconciliation === "False") {
      count.falseCount = ++count.falseCount;
    }
    if (this.state.PCID_Q2_Missed_Error_Reconciliation === "True") {
      count.trueCount = ++count.trueCount;
    }
    if (this.state.PCID_Q2_Missed_Error_Reconciliation === "False") {
      count.falseCount = ++count.falseCount;
    }
    if (this.state.PC_Q1_IPRC_Error_Reconciliation === "True") {
      count.trueCount = ++count.trueCount;
    }
    if (this.state.PC_Q1_IPRC_Error_Reconciliation === "False") {
      count.falseCount = ++count.falseCount;
    }
    if (
      this.state
        .bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation ===
      "True"
    ) {
      count.trueCount = ++count.trueCount;
    }
    if (
      this.state
        .bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation ===
      "False"
    ) {
      count.falseCount = ++count.falseCount;
    }
    return count;
  }
  aeidMatch() {
    if (
      this.state.AEID_Q2_AE_Missed_QualityMonitorQC !== undefined &&
      this.state.AEID_Q2_AE_Missed_QualityMonitorQC !== "" &&
      this.state.AEID_Q2_AE_Missed_Amgen !== "" &&
      this.state.AEID_Q2_AE_Missed_Amgen !== undefined
    ) {
      if (
        this.state.AEID_Q2_AE_Missed_QualityMonitorQC ===
        this.state.AEID_Q2_AE_Missed_Amgen
      ) {
        this.setState({ AEID_Q2_AE_Error_Reconciliation: "True" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      } else {
        this.setState({ AEID_Q2_AE_Error_Reconciliation: "False" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      }
    } else {
      this.setState({ AEID_Q2_AE_Error_Reconciliation: "-" }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount });
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage });
        }
      });
    }
  }
  AE_Cap_IRPC_Match() {
    if (
      this.state.AE_Cap_IRPC_Match_QualityMonitorQC !== undefined &&
      this.state.AE_Cap_IRPC_Match_QualityMonitorQC !== "" &&
      this.state.AE_Cap_IRPC_Match_Amgen !== "" &&
      this.state.AE_Cap_IRPC_Match_Amgen !== undefined
    ) {
      if (
        this.state.AE_Cap_IRPC_Match_QualityMonitorQC ===
        this.state.AE_Cap_IRPC_Match_Amgen
      ) {
        this.setState(
          { AE_Cap_IRPC_Match_Error_Reconciliation: "True" },
          () => {
            let count = this.caliculation();
            this.setState({ Total_Error_Reconciliation: count.trueCount });
            if (count.trueCount !== 0) {
              let percentage = (count.trueCount / 7) * 100;
              this.setState({ Quality_Percentage_INT: percentage });
            }
          }
        );
      } else {
        this.setState(
          { AE_Cap_IRPC_Match_Error_Reconciliation: "False" },
          () => {
            let count = this.caliculation();
            this.setState({ Total_Error_Reconciliation: count.trueCount });
            if (count.trueCount !== 0) {
              let percentage = (count.trueCount / 7) * 100;
              this.setState({ Quality_Percentage_INT: percentage });
            }
          }
        );
      }
    } else {
      this.setState({ AE_Cap_IRPC_Match_Error_Reconciliation: "-" }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount });
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage });
        }
      });
    }
  }
  AE_Q2_HCP() {
    if (
      this.state.AE_Q2_HCP_QualityMonitorQC !== undefined &&
      this.state.AE_Q2_HCP_QualityMonitorQC !== "" &&
      this.state.AE_Q2_HCP_Amgen !== "" &&
      this.state.AE_Q2_HCP_Amgen !== undefined
    ) {
      if (
        this.state.AE_Q2_HCP_QualityMonitorQC === this.state.AE_Q2_HCP_Amgen
      ) {
        this.setState({ AE_Q2_HCP_Error_Reconciliation: "True" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      } else {
        this.setState({ AE_Q2_HCP_Error_Reconciliation: "False" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      }
    } else {
      this.setState({ AE_Q2_HCP_Error_Reconciliation: "-" }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount });
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage });
        }
      });
    }
  }
  AE_Q3_IPRC() {
    if (
      this.state.AE_Q3_IPRC_QualityMonitorQC !== undefined &&
      this.state.AE_Q3_IPRC_QualityMonitorQC !== "" &&
      this.state.AE_Q3_IPRC_Amgen !== "" &&
      this.state.AE_Q3_IPRC_Amgen !== undefined
    ) {
      if (
        this.state.AE_Q3_IPRC_QualityMonitorQC === this.state.AE_Q3_IPRC_Amgen
      ) {
        this.setState({ AE_Q3_IPRC_Error_Reconciliation: "True" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      } else {
        this.setState({ AE_Q3_IPRC_Error_Reconciliation: "False" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      }
    } else {
      this.setState({ AE_Q3_IPRC_Error_Reconciliation: "-" }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount });
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage });
        }
      });
    }
  }
  PCID_Q2_Missed() {
    if (
      this.state.PCID_Q2_Missed_QualityMonitorQC !== undefined &&
      this.state.PCID_Q2_Missed_QualityMonitorQC !== "" &&
      this.state.PCID_Q2_Missed_Amgen !== "" &&
      this.state.PCID_Q2_Missed_Amgen !== undefined
    ) {
      if (
        this.state.PCID_Q2_Missed_QualityMonitorQC ===
        this.state.PCID_Q2_Missed_Amgen
      ) {
        this.setState({ PCID_Q2_Missed_Error_Reconciliation: "True" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      } else {
        this.setState({ PCID_Q2_Missed_Error_Reconciliation: "False" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      }
    } else {
      this.setState({ PCID_Q2_Missed_Error_Reconciliation: "-" }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount });
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage });
        }
      });
    }
  }
  PC_Q1_IPRC_Match() {
    if (
      this.state.PC_Q1_IPRC_Match_QualityMonitorQC !== undefined &&
      this.state.PC_Q1_IPRC_Match_QualityMonitorQC !== "" &&
      this.state.PC_Q1_IPRC_Match_Amgen !== "" &&
      this.state.PC_Q1_IPRC_Match_Amgen !== undefined
    ) {
      if (
        this.state.PC_Q1_IPRC_Match_QualityMonitorQC ===
        this.state.PC_Q1_IPRC_Match_Amgen
      ) {
        this.setState({ PC_Q1_IPRC_Error_Reconciliation: "True" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      } else {
        this.setState({ PC_Q1_IPRC_Error_Reconciliation: "False" }, () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        });
      }
    } else {
      this.setState({ PC_Q1_IPRC_Error_Reconciliation: "-" }, () => {
        let count = this.caliculation();
        this.setState({ Total_Error_Reconciliation: count.trueCount });
        if (count.trueCount !== 0) {
          let percentage = (count.trueCount / 7) * 100;
          this.setState({ Quality_Percentage_INT: percentage });
        }
      });
    }
  }
  bioconnect_Match_capturedIn_probing_decisionTree_originalQM() {
    if (
      this.state
        .bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC !==
        undefined &&
      this.state
        .bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC !==
        "" &&
      this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC !==
        "" &&
      this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC !==
        undefined
    ) {
      if (
        this.state
          .bioconnect_Match_capturedIn_probing_decisionTree_QualityMonitorQC ===
        this.state.bioconnect_Match_capturedIn_probing_decisionTree_amgenQC
      ) {
        this.setState(
          {
            bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation:
              "True",
          },
          () => {
            let count = this.caliculation();
            this.setState({ Total_Error_Reconciliation: count.trueCount });
            if (count.trueCount !== 0) {
              let percentage = (count.trueCount / 7) * 100;
              this.setState({ Quality_Percentage_INT: percentage });
            }
          }
        );
      } else {
        this.setState(
          {
            bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation:
              "False",
          },
          () => {
            let count = this.caliculation();
            this.setState({ Total_Error_Reconciliation: count.trueCount });
            if (count.trueCount !== 0) {
              let percentage = (count.trueCount / 7) * 100;
              this.setState({ Quality_Percentage_INT: percentage });
            }
          }
        );
      }
    } else {
      this.setState(
        {
          bioconnect_Match_capturedIn_probing_decisionTree_Error_Reconciliation:
            "-",
        },
        () => {
          let count = this.caliculation();
          this.setState({ Total_Error_Reconciliation: count.trueCount });
          if (count.trueCount !== 0) {
            let percentage = (count.trueCount / 7) * 100;
            this.setState({ Quality_Percentage_INT: percentage });
          }
        }
      );
    }
  }
  handleChangeYesNo(e, question, id) {
    e.persist();
    if (id) {
      const questions = this.state.questionaries.length
        ? this.state.questionaries
        : this.state.questionaries;
      questions.map((values, i) => {
        if (values.id === id) {
          questions[i].answer = e.target.value;
        }
        return values;
      });
      this.setState({
        questionaries: questions,
      });
      e.target.classList.remove("invalid");
    } else {
      this.setState({ [e.target.name]: e.target.value });
      e.target.classList.remove("invalid");
    }
  }

  questionsetNameChanges(e) {
    e.persist();
    // console.log('eee', e)
    const questionset = this.state.questionsets.filter((question) => {
      if (question.id == e.target.value) {
        // let questionswithId = [];
        // question.questionaries.map((que, index)=>{
        //   que.id=index+1;
        //   questionswithId.push(que);
        // })

        return question;
      }
    });
    // console.log(questionset, 'line 709');
    this.setState({
      questionaries: questionset[0].questionaries,
      selectedQuestionset: e.target.value,
      questionsetName: questionset[0].questionsetName,
      questionsetid: questionset[0].id,
    });
  }

  showHistory() {
    this.setState({ showComment: true });
  }
  hideHistory() {
    this.setState({ showComment: false });
  }

  showAdminDetails() {
    const isError = this.handleScorecardValidation();
    const isValid = this.handleValidation();
    // const isDuplicateScName = this.checkDuplicateScorecardName()

    if (isError || isValid) {
      this.setState({ scorecardErrorModel: true });
    } else {
      /* else if(isDuplicateScName) {
      this.setState({ duplicateScorecard: true })
    } */
      let data = { isScorecard: 1 };
      let self = this;
      utils.showLoader();
      utils.makeRequest(
        this,
        EndpointConstant.GET_USER_LIST,
        data,
        function (result) {
          utils.hideLoader();
          const users = result.users.filter(
            (val) =>
              val.status == "2" &&
              (val.role == RoleConstant.SuperAdmin ||
                val.role == RoleConstant.SAdmin ||
                val.role == RoleConstant.Reviewer)
          );
          self.setState({ adminUsers: users, showAdminPopup: true });
        }
      );
    }
  }

  handleAdminPopupClose() {
    this.setState({ showAdminPopup: false });
  }

  submitScorecard() {
    this.setState({ showAdminPopup: false });
    this.updateScorecard();
  }

  getScoreCardList() {
    let data = {
      datasetId: "All",
    };
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.SCORECARDS_LISTS_FROM_DATASET,
      data,
      async function (result) {
        //utils.showLoader();
        await fetch(result.scorecards, {
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          method: "GET",
          body: null,
        })
          .then((response) => response.json())
          .then((s3ResponseJson) => {
            const scorecards = s3ResponseJson;
            self.setState({ scorecards: scorecards });
            utils.hideLoader();
          })
          .catch((error) => {
            let er;
            // console.error(error);
            // console.log("Testing 12345 Error");
          });
        });
  }

  checkDuplicateScorecardName() {
    if (this.props.scoreCard === "new") {
      const scorecards = this.state.scorecards;
      const scorecardName = this.state.ScorecardName.trim();
      return scorecards.some(
        (sc) => sc.ScorecardName.toUpperCase() === scorecardName.toUpperCase()
      );
    } else {
      return false;
    }
  }

  render() {
    // this.isValidForm();
    // let scorecarDetails=this.props.details;
    //// // console.log(scorecarDetails,"editscorecardetails")
    let isExistingQuestionset =
      this.state.questionsets.length &&
      this.state.questionsets.some(
        (val) => val.id === this.state.questionsetid
      );
    let questionsetOptions = [];
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    if (this.state.questionsets.length > 0) {
      questionsetOptions = this.state.questionsets.map((question) => {
        // console.log("dataset", question)
        // return questionsetOptions.push({ id: question.id, label: question.questionsetName, value: question.id });
        return (
          <option key={question.id} value={question.id}>
            {question.questionsetName}
          </option>
        );
      });
    }
    //const currentUserId = utils.getSessionItem("userId");
    const ifrOptions = CommonService.ifrType.map((status, i) => {
      return (
        <option key={i} value={status.value}>
          {status.value}
        </option>
      );
    });
    const channelOptions = siteinfo.Channel.map((channel, i) => {
      return (
        <option key={i} value={channel}>
          {channel}
        </option>
      );
    });
    // const AEID_Q2_AE_Missed=this.state.errors.AEID_Q2_AE_Missed
    let scoreDetails = this.state;
    let siteInfoProducts = siteinfo.Product;
    const {
      caseType,
      channel,
      QMAssociate,
      amgenQCBy,
      subcaseNumber,
      datasetNameView,
      datasetName,
      questionStatus,
      ScorecardName,
    } = scoreDetails;
    let { product } = scoreDetails;
    if (!siteinfo.Product[product]) {
      siteInfoProducts[product] = product;
    } else {
      product = siteinfo.Product[product];
    }
    const productOptions =
      siteInfoProducts &&
      Object.keys(siteInfoProducts)
        .sort()
        .map((product, index) => {
          return (
            <option key={index} value={siteInfoProducts[product]}>
              {product}
            </option>
          );
        });
    // const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const role = utils.getSessionItem("role");
    // // console.log(siteinfo.ScorecardLabels);
    // const scoreCardLables = siteinfo.ScorecardLabels;
    const isFormDisabled =
      ((utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
        utils.getSessionItem("role") === RoleConstant.SAdmin) &&
        this.state.createdBy === utils.getSessionItem("userId") &&
        (this.state.stage !== "otherAdmin" ||
          this.props.scoreCard === "new")) ||
      ((utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
        utils.getSessionItem("role") === RoleConstant.SAdmin) &&
        this.state.createBy === utils.getSessionItem("userId")) ||
      utils.getSessionItem("role") === RoleConstant.Admin;

    const isScorecardNameFieldDisabled =
      ((utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
        utils.getSessionItem("role") === RoleConstant.SAdmin) &&
        this.state.createdBy === utils.getSessionItem("userId") &&
        (this.state.stage !== "otherAdmin" ||
          this.props.scoreCard === "new")) ||
      ((utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
        utils.getSessionItem("role") === RoleConstant.SAdmin) &&
        this.state.createBy === utils.getSessionItem("userId")) ||
      utils.getSessionItem("role") === RoleConstant.Admin ||
      this.state.ScorecardName != "";

    const backButton = (
      <Button
        variant="secondary"
        className="mt-4 clear follow-up-back mr-2"
        onClick={this.goBackTo}
      >
        <img
          src="Images/icon-arrow-left.png"
          className="btn-back"
          alt="Amgen"
        />
        Back
      </Button>
    );

    const completeButton =
      this.state.questionaries.length &&
      (((utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
        utils.getSessionItem("role") === RoleConstant.SAdmin) &&
        this.state.createdBy === utils.getSessionItem("userId")) ||
        utils.getSessionItem("role") === RoleConstant.Admin) ? (
        <Button
          onClick={this.showAdminDetails}
          className="mt-4 button-color follow-up-back"
        >
          Submit
        </Button>
      ) : (
        ""
      );

    const completeScorecardButton =
      this.state.questionaries && this.state.questionaries.length ? (
        <Button
          onClick={this.completeScorecard}
          className="mt-4 button-color follow-up-back"
        >
          Complete
        </Button>
      ) : (
        ""
      );
    const routetoQM =
      (utils.getSessionItem("role") === RoleConstant.Reviewer ||
        utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
        utils.getSessionItem("role") === RoleConstant.SAdmin) &&
      this.state.createdBy !== utils.getSessionItem("userId") ? (
        <Button
          onClick={this.routetoQM}
          className="mt-4 button-color mr-2 follow-up-back"
        >
          Route To User
        </Button>
      ) : (
        ""
      );
    const dropdown = (options, selected, question, type, id) => {
      let self = this;
      let dropdwonValues;
      if (type === "SingleSelect") {
        if (options) {
          options = { 0: "--Select--", ...options };
          dropdwonValues = Object.keys(options).map((option) => {
            return option === "0" ? (
              <option hidden value="" key={option}>
                {options[option]}
              </option>
            ) : (
              <option value={options[option]} key={option}>
                {options[option]}
              </option>
            );
          });
        }
        return (
          <Form.Control
            as="select"
            size="sm"
            id={id}
            name={question}
            onChange={(e) => self.handleChangeYesNo(e, question, id)}
            disabled={!isFormDisabled ? true : null}
            className="am-form-placeholder"
            value={selected}
          >
            {dropdwonValues}
          </Form.Control>
        );
      } else if (type === "MultiSelect") {
        let multiselectOptions = [];
        Object.keys(options).map((option) => {
          return multiselectOptions.push({
            id: option,
            label: options[option],
            value: options[option],
          });
        });
        return (
          <div id={id} class="MultiSelectContainer">
            <MultiSelect
              options={multiselectOptions}
              selected={selected}
              disableSearch={false}
              disabled={!isFormDisabled}
              hasSelectAll={true}
              onClick={this.handleChangeYesNo}
              onSelectedChanged={(selectedItems) => {
                let ConainerElem = event.target.closest(
                  ".MultiSelectContainer.invalid"
                );
                if (ConainerElem) {
                  ConainerElem.classList.remove("invalid");
                }
                const question = this.state.questionaries;
                question.forEach((val, i) => {
                  if (val.id === id) {
                    Object.assign(question[i], { answer: selectedItems });
                  }
                });

                self.setState({
                  questionaries: question,
                });
              }}
              overrideStrings={{
                selectSomeItems: "--Select--",
                allItemsAreSelected: "All are Selected",
                selectAll: "Select All",
              }}
            />
          </div>
        );
      } else if (type === "Text") {
        return (
          <Form.Control
            type="text"
            id={id}
            name={question}
            onChange={(e) => self.handleChangeYesNo(e, question, id)}
            value={selected}
            disabled={!isFormDisabled}
          />
        );
      } else {
        return (
          <Form.Control
            as="select"
            id={id}
            size="sm"
            name={question}
            onChange={(e) => self.handleChangeYesNo(e, question, id)}
            disabled={!isFormDisabled ? true : null}
            value={selected}
          >
            {/* {dropdwonValues} */}
            <option value="" key="" hidden>
              --Select--
            </option>
            <option value="yes" key="Yes">
              Yes
            </option>
            <option value="no" key="No">
              No
            </option>
            <option value="n/a" key="N/A">
              N/A
            </option>
          </Form.Control>
        );
      }
    };

    let commentHist = this.state.comment;
    let ccAdmin = this.state.interactionOwnerName
      ? this.state.interactionOwnerName
      : this.state.interactionOwner;

    const adminOption = this.state.adminUsers.sort().map((role, i) => {
      return (
        <option
          key={i}
          value={role.id}
        >{`${role.firstName} ${role.lastName} (${role.role})`}</option>
      );
    });

    // console.log("selectedAdmin", this.state.selectedAdmin,'adminOption',this.state.adminUsers);
    return (
      <div>
        <div className="content-layout">
          {this.state.showViewDetails ? (
            <ViewScoreCardDetails
              details={this.state.scorecardDetails}
              hideDetails={this.hideViewDetails}
              scoreDetails="new"
            />
          ) : (
            <div>
              <div className="score-details-layout">
                <div className="score-card">
                  <Form onSubmit={this.handleSearch} className="editScoreForm">
                    <Form.Row>
                      <Col md="4">
                        <Form.Label>Sub-Case Number</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          name="subcaseNumber"
                          value={subcaseNumber.toUpperCase()}
                          onChange={this.handleChangeYesNo}
                        />
                        {/* </Form.Group> */}
                      </Col>
                      <Col md="4">
                        <Form.Label>Dataset</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          name="datasetName"
                          value={
                            this.props.scoreCard === "new"
                              ? datasetNameView
                              : datasetName
                          }
                          onChange={this.handleChangeYesNo}
                        />
                        {/* </Form.Group> */}
                      </Col>
                      {/* <Col md='3'>
                        <Form.Label>Case Type</Form.Label>
                        <Form.Control as='select' name='caseType' disabled value={caseType} onChange={this.handleChangeYesNo}> */}
                      {/* <option value="">--- ALL ---</option> */}
                      {/* {ifrOptions}
                        </Form.Control>
                      </Col> */}
                      <Col md="4">
                        <Form.Label>Channel</Form.Label>
                        <Form.Control
                          as="select"
                          disabled
                          name="channel"
                          value={channel}
                          onChange={this.handleChangeYesNo}
                        >
                          <option value="">--Select--</option>
                          {channelOptions}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="am-questions">
                      <Col md="4">
                        <Form.Label>Products</Form.Label>
                        <Form.Control
                          as="select"
                          id="product"
                          name="product"
                          value={product}
                          onChange={this.handleChangeYesNo}
                          disabled={!isFormDisabled ? true : null}
                        >
                          <option value="">--Select--</option>
                          {productOptions}
                          <option value="N/A">N/A</option>
                        </Form.Control>
                      </Col>
                      <Col md="4">
                        <Form.Label>Call Center Agent</Form.Label>
                        <Form.Control
                          type="text"
                          name="callcenterAdmin"
                          disabled
                          value={ccAdmin}
                        />
                      </Col>
                      <Col md="4">
                        <Form.Label>Created by Team</Form.Label>
                        <Form.Control
                          type="text"
                          name="associatedTeam"
                          disabled
                          value={this.state.associatedTeam}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="am-questions">
                      {
                        <Col md="4">
                          <Form.Label>Question Set</Form.Label>

                          <Form.Control
                            as="select"
                            name="questionsetName"
                            onChange={(e) => this.questionsetNameChanges(e)}
                            className="am-form-placeholder"
                            value={
                              !isExistingQuestionset
                                ? ""
                                : this.state.selectedQuestionset
                            }
                            disabled={!isFormDisabled}
                          >
                            <option value="" disabled>
                              {" "}
                              -- Select --
                            </option>
                            {questionsetOptions}
                          </Form.Control>
                        </Col>
                      }
                      <Col md="4">
                        <Form.Label>QM Associate</Form.Label>
                        <Form.Control
                          type="text"
                          name="QMAssociate"
                          value={QMAssociate}
                          onChange={(e) => {
                            this.setState({ QMAssociate: e.target.value });
                          }}
                          disabled
                        />
                      </Col>

                      <Col md="4">
                        <Form.Label>Amgen QC By</Form.Label>
                        <Form.Control
                          type="text"
                          name="amgenQCBy"
                          disabled
                          value={amgenQCBy ? amgenQCBy : "-"}
                          onChange={(e) => {
                            this.setState({ amgenQCBy: e.target.value });
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="am-questions">
                      <Col md="4">
                        <Form.Label>Scorecard Name</Form.Label>

                        <Form.Control
                          type="text"
                          name="cardName"
                          value={
                            ScorecardName
                              ? ScorecardName
                              : this.state.ScorecardName
                          }
                          onChange={(e) => {
                            this.setState({ ScorecardName: e.target.value });
                          }}
                          disabled={!isFormDisabled}
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                  {/* <Row className='row-end-border'>
                </Row> */}
                  {this.state.questionaries &&
                  this.state.questionaries.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th className="original-qm-width">
                            {this.props.scoreCard === "new"
                              ? this.state.questionsetName
                              : this.props.questionaries.questionsetName}{" "}
                            {!isExistingQuestionset && (
                              <span className="am-required">*</span>
                            )}
                          </th>
                          <th>Options</th>
                          <th>Weightage</th>
                          {/* <th className='qc-error-width'>Quality Monitor QC</th>
                        <th>Amgen QC</th>
                        <th className='qc-error-width'>Error Reconciliation</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.questionaries.map((questions, index) => {
                          const selectedValue = questions.answer
                            ? questions.answer
                            : "";
                          return (
                            <tr key={index}>
                              <td>
                                <b>{questions.question}</b>
                              </td>
                              <td>
                                {dropdown(
                                  questions.options,
                                  selectedValue,
                                  questions.question,
                                  questions.type,
                                  questions.id
                                )}
                              </td>
                              <td>{questions.weightage}</td>
                              {/* <td>{scoreDetails.AEID_Q2_AE_Error_Reconciliation}</td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </div>

                <div className="layout-2">
                  <Row>
                    <Col md={12} sm={12}>
                      <label className="mb-2">Comments</label>

                      {/* {this.props.scoreCard==='new'? */}
                      <Form.Control
                        as="textarea"
                        name="newcomment"
                        value={this.state.newcomment}
                        onChange={this.handleChangeYesNo}
                      />
                    </Col>
                  </Row>
                  {this.state.questionaries &&
                  this.state.questionaries.length > 0 ? (
                    <Row>
                      <Col md={2.5} sm={2.5} className="pl-3">
                        <label className="mb-2">
                          Scorecard Question Status
                        </label>
                      </Col>
                      <Col md={3}>
                        <Form.Control
                          as="select"
                          id="quesStatus"
                          name="questionStatus"
                          value={questionStatus ? questionStatus : ""}
                          onChange={this.handleChangeYesNo}
                          disabled={!isFormDisabled ? true : null}
                        >
                          <option value="" hidden disabled>
                            --Select--
                          </option>
                          <option value="pass">Pass</option>
                          <option value="fail">Fail</option>
                          <option value="N/A">N/A</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="row"><div className="col-xs-12 col-md-12"> 
      <button className="col-md-1 offset-11 btn-gobackTo mt-4 mb-4 pull-right" onClick={this.goBackTo}>Back</button></div>
    </div> */}
              <div className="row pull-right">
                <div className="col-xs-12 col-md-12">
                  {/* <Button type="submit" onClick={this.showFollowUp} className='mt-4 button-color follow-up-back'>Follow Up</Button> */}
                  {backButton} {completeScorecardButton} {routetoQM}
                  {completeButton}
                </div>
              </div>
            </div>
          )}
          <PopupModels
            showPopup={this.state.scorecardUpdateModel}
            iconFlag="success"
            message={
              this.props.scoreCard === "new"
                ? MessageConstant.ADD_SCORECARD
                : this.state.isComplete
                ? MessageConstant.COMPLETED_SCORECARD
                : MessageConstant.UPDATE_SCORECARD
            }
            handleClosePopup={this.handleUpdateClose.bind(this)}
          />
          <PopupModels
            showPopup={this.state.scorecardErrorModel}
            iconFlag="error"
            message={MessageConstant.SCORECARD_ERROR}
            handleClosePopup={this.handleErrorClose.bind(this)}
          />
          <PopupModels
            showPopup={this.state.scorecardNoUserError}
            iconFlag="error"
            message={MessageConstant.SCORECARD_NO_USER_ERROR}
            handleClosePopup={this.handleNoUserErrorClose.bind(this)}
          />
          <PopupModels
            showPopup={this.state.duplicateScorecard}
            iconFlag="error"
            message={MessageConstant.DULICATE_SCORECARD}
            handleClosePopup={this.handleDuplicateSCNameError.bind(this)}
          />
          <Modal
            size="lg"
            show={this.state.showComment}
            onHide={this.hideHistory}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Comment History
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="scrollcommentpopup">
              <CommentHistory commentHist={commentHist} />
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showAdminPopup}
            onHide={this.handleAdminPopupClose}
          >
            <Modal.Header closeButton className="add-user-header">
              Assign To
            </Modal.Header>
            <Form.Row className="reg-form">
              <Form.Group
                as={Col}
                controlId="adminName"
                className="mt-4 pl-4 pr-4"
              >
                <Form.Label className="align-lable">
                  Select users to assign scorecard
                </Form.Label>
                <Form.Control
                  as="select"
                  name="assign"
                  value={this.state.selectedAdmin}
                  onChange={(e) => {
                    this.setState({ selectedAdmin: e.target.value });
                  }}
                >
                  <option value="">--Select--</option>
                  {adminOption}
                  <option value="Admin">Admin</option>
                  {/*  <option value='Reviewer'>Reviewer</option>   */}
                  <option value="SuperAdmin">SuperAdmin</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group as={Col} controlId="adminName" className="pl-4 pr-4">
              <Button
                variant="secondary"
                type="button"
                className="button-color mb-4 float-right"
                onClick={this.submitScorecard}
                disabled={this.state.selectedAdmin.length === 0}
              >
                {" "}
                Submit{" "}
              </Button>
            </Form.Group>
          </Modal>
        </div>
      </div>
    );
  }
}
EditScoreCard.propTypes = {
  details: PropTypes.object,
  scoreCard: PropTypes.string,
  hideDetails: PropTypes.func,
  hideEditScoreCard: PropTypes.func,
  questionaries: PropTypes.array,
};

export default EditScoreCard;
