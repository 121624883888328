import React, { Component } from 'react';
import './FilterCountryTotals.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap';
// import CommonService from '../../../commonService/CommonService';
import EndpointConstant from '../../../constants/EndpointConstant';
import RoleConstant from "../../../constants/RoleConstant";
import utils from '../../../utils';
import PropTypes from 'prop-types';
import MultiSelect from "@khanacademy/react-multi-select";
//import { select } from 'd3';
class FilterCountryTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconName: 'icon-drop-up',
            country: '',
            teamName: '',
            dataSet: [],
            datasetId: '',
            toggleButton: false,
        }
        this.iconChange = this.iconChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnClear = this.handleOnClear.bind(this);
        this.handleMyreport = this.handleMyreport.bind(this);
        this.getDatasetList = this.getDatasetList.bind(this);
        this.toggleStates = this.toggleStates.bind(this);
    }
    componentDidMount() {
        this.getDatasetList();
    }
    iconChange() {
        if (this.state.iconName === 'icon-drop-up') {
            this.setState({ iconName: 'icon-drop-down' })
        } else {
            this.setState({ iconName: 'icon-drop-up' })
        }
    }
    handleOnChange(event) {
        //console.log("onchange", event);
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    handleOnClear() {
        this.setState({
            country: '',
            teamName: '',
            dataSet: [],
            datasetId: '',
        },()=>{
            this.getDatasetList(1);
            this.toggleStates('', 1);
        });
      
       
        // this.props.getCountryTotals();
        // this.props.onDownloadFilter({});
    }

     /*
    * Toggle MyReport and All reports
    */
     toggleStates(event, isClear = 0) {
        //  if(event) {
        //     event.preventDefault();
        //  }
         
        //  console.log('hello', isClear, this.state.toggleButton)
        const currentToggleStatus = isClear ? this.state.toggleButton: !this.state.toggleButton;
		this.setState({
			toggleButton: currentToggleStatus
		});
        this.handleMyreport(!currentToggleStatus);
	}

    /*
    * Handle my report for admin role
    */
    handleMyreport(isToggle) {
        let dupDataset = [];
        if(!isToggle) {
            let currentUserId = utils.getSessionItem("userId");
            dupDataset = this.state.dataSet.filter(datasetDetails =>datasetDetails.createdBy === currentUserId ).map(val => val.id);
            // console.log('reresss', this.state.datasetId, dupDataset)
            dupDataset = this.state.datasetId.length ? dupDataset.filter(val=> this.state.datasetId.some(val1=> val1 === val)) : dupDataset;
            // console.log('ataset', dupDataset)
            this.getCountryReport(dupDataset, 1);
        } else {
           this.getCountryReport([], 0);
        }
       
        // let self = this;
        // let data = {};
        
        // if (dupDataset.length!==0) {
        //     data.datasetId = dupDataset
        // } else {
        //     data.datasetId = ['myReport']
        // }
        // utils.makeRequest(this, EndpointConstant.FILTER_COUNTRYTOTALS, data, function (result) {
        //     self.props.countryTotalsData(result, 'filter');
        //     self.props.onDownloadFilter(data);
        // })
    }

    getDatasetList(isClear=0) {
        let data = {};
        let self = this;
        //console.log('self>>>>>',self)
        utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
            result.datasets.sort(self.compare);
            //console.log(result);
            // result.datasets.unshift({id: 0, label: "My Report"}, {id:1 , label: "User Report"})
            self.setState({
                dataSet: result.datasets,
            }, () => {
                // if(isClear) {
                //     self.toggleStates('', 1)
                // }
            });
        });
    }
    handleSearch(event) {
        event.preventDefault();
        // let dupDataset = [];
        // console.log("handle search", this.state);
        // if (this.state.datasetId.length) {
        // if (this.state.datasetId.some(val=> val === "myReport")) {
        //     let currentUserId = utils.getSessionItem("userId");
        //     dupDataset = this.state.dataSet.filter(datasetDetails =>datasetDetails.createdBy === currentUserId ).map(val => val.id);
        //  }
        

        // if(this.state.datasetId.some(val => val === "userReport")) {
        //     let data = {};
        //     let self = this;
        //     utils.showLoader();
        //     utils.makeRequest(this, EndpointConstant.GET_USER_LIST, data, function (result) {
        //         utils.hideLoader();
        //         let users = result.users.filter(user => user.role === RoleConstant.Admin);
        //         let data = self.state.dataSet.filter(datasetDetails => {
        //            return  users.some(user => user.id === datasetDetails.createdBy);
        //         }).map(val => val.id);
        //         data = [...dupDataset, ...data];
        //         dupDataset = data.filter((val, i) => data.indexOf(val) === i);
        //     });
        // } 
        // let data = [...this.state.datasetId, ...dupDataset];
        //     dupDataset = data.filter((val, i) => data.indexOf(val) === i);
        // let selectedDataset = options.filter(datasetDetails =>{
        //     return  this.state.datasetId.some(data => data === datasetDetails.value);
        //     }).map(val => val.id); 
        //     console.log('dataser', selectedDataset, dupDataset, this.state.selected, options)
        // console.log('dup', dupDataset, this.state.datasetId, this.state.datasetId)
        // let datas = [...this.state.datasetId, ...dupDataset];
        // dupDataset = datas.filter((val, i) => datas.indexOf(val) === i);
        // }
        // console.log('dup', dupDataset, datas)
        // console.log('sdsdsds', !this.state.toggleButton)
       this.handleMyreport(!this.state.toggleButton)

    }

    getCountryReport(dupDataSet, isUser) {
        let self = this
        let data = {}
        if (this.state.country.length!==0) {
            data.country = this.state.country
        }
        if (this.state.teamName.length!==0) {
            data.teamName = this.state.teamName
        }
        if (isUser) {
            data.datasetId = this.state.datasetId ? this.state.datasetId : 
            dupDataSet.length ? dupDataSet :  [];
            data.isMyReport = true;
            // // data.datasetId = dupDataset
            // data.datasetId = this.state.datasetId;
        } else if (this.state.datasetId.length !== 0) {
            data.datasetId = this.state.datasetId;
        }
        // console.log('data', data)
    
        // utils.makeRequest(this, EndpointConstant.FILTER_COUNTRYTOTALS, data, function (result) {
        utils.makeRequest(this, EndpointConstant.COUNTRY_TOTALS_FROM_FILE, data, function (result) {
            self.props.countryTotalsData(result, 'filter');
            self.props.onDownloadFilter(data);
            // self.props.resetPagination();
            // self.loading = false;
        })
    }

    compareDataset(a, b) {
        const valueA = a.label.toUpperCase();
        const valueB = b.label.toUpperCase();
    
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } 
    render() {
        const {dataSet} = this.state;
        let countryList = [];
        // const countryList = this.props.countryList.sort().map((countryList, index) => {
        //     return (
        //         <option key={index} value={countryList}>{countryList}</option>
        //     )
        // });
        this.props.countryList.sort().map((country, i) => {
            return countryList.push({ id: country, label: country, value: country });
           
          });
          let datasetList = [];
        // const datasetList = dataSet.sort().map((dataset, index) => {
        //     if (dataset.status === 'Processing') {
        //         return null;
        //     }
        //     return (
        //         <option key={index} value={dataset.id}>{dataset.datasetName && dataset.datasetName.split('.')[0]}</option>
        //     )
        // });
        dataSet.sort().forEach((dataset, i) => {
            if(dataset.status ===  'Complete') {
            return datasetList.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
            }
          });
          datasetList.sort(this.compareDataset);
        //   if (utils.getSessionItem("role") === RoleConstant.SuperAdmin) {
        //      datasetList.unshift({id: 0, label: "My Report", value: 'myReport'}, {id:1 , label: "User Report", value: "userReport"})
        //   }
        // const teamList = this.props.teamNames.sort().map((team, i) => {
        //     return (
        //         <option key={i} value={team}>{team}</option>
        //     )
        // })
        let teamList = []
        this.props.teamNames.sort().map((team, i) => {
            return teamList.push({ id: team, label: team, value: team });
           
          });
        return (
            <Accordion defaultActiveKey="0" className='cardPadding'>
                <Card >
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
                        <span className="search-title">FILTER COUNTRY TOTALS</span> <Image src="/Images/ico-drop-up.png" className={this.state.iconName} aria-hidden="true"></Image>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className='cardBodyStyle'>
                            <Form onSubmit={this.handleSearch} className="searchForm">
                                <Form.Row>
                                    <Col>
                                        <Form.Label>Country</Form.Label>
                                        {/* <Form.Control as='select' name='country' value={country} onChange={this.handleOnChange}>
                                            <option value="">--- ALL ---</option>
                                            {countryList}
                                        </Form.Control> */}
                                        <MultiSelect
                                            options={countryList}
                                            selected={this.state.country}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            onSelectedChanged={selected => {
                                                // console.log("selected values", selected);
                                                this.setState({ country: selected });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Team Name</Form.Label>
                                        {/* <Form.Control as='select' name='teamName' value={teamName} onChange={this.handleOnChange}>
                                            <option value="">--- ALL ---</option>
                                            {teamList}
                                        </Form.Control> */}
                                        <MultiSelect
                                            options={teamList}
                                            selected={this.state.teamName}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            onSelectedChanged={selected => {
                                                // console.log("selected values", selected);
                                                this.setState({ teamName: selected });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>Data Set</Form.Label>
                                        {/* <Form.Control as='select' name='datasetId' value={datasetId} onChange={this.handleOnChange}>
                                            <option value="">--- ALL ---</option>
                                            {datasetList}
                                        </Form.Control> */}
                                         <MultiSelect
                                            options={datasetList}
                                            selected={this.state.datasetId}
                                            disableSearch={false}
                                            hasSelectAll={true}
                                            onSelectedChanged={selected => {
                                                // console.log("selected values", selected);
                                                this.setState({ datasetId: selected });
                                                return null;
                                            }
                                            }
                                            overrideStrings={{
                                                selectSomeItems: "--Select--",
                                                allItemsAreSelected: "All are Selected",
                                                selectAll: "Select All"
                                            }}
                                        />
                                    </Col>

                                    <Button variant="secondary" type="submit" className='scorecardSearch button-color userSearch'>Search</Button>
                                    {/* { utils.getSessionItem("role") === RoleConstant.SuperAdmin && <Button variant="secondary" className='scorecardSearch button-color userSearch' onClick={this.handleMyreport}>My Report</Button> } */}

                                    {
                                        (utils.getSessionItem("role") === RoleConstant.SuperAdmin || utils.getSessionItem("role") === RoleConstant.SAdmin) && <div className='switch-field1'>
                                            <Form.Check
                                                inline
                                                label="All Report"
                                                type='radio'
                                                name="switchToggle1"
                                                id='switch_left1'
                                                value='All Report1'
                                                checked={!this.state.toggleButton}
                                                onChange={this.toggleStates}
                                                className='switch_left1'
                                            />
                                            <Form.Check
                                                inline
                                                label="My Report"
                                                type='radio'
                                                name="switchToggle1"
                                                id='switch_right1'
                                                value='My Report1'
                                                checked={this.state.toggleButton}
                                                onChange={this.toggleStates}
                                            />
                                        </div>
                                    }
                                    <Button variant="secondary" className='clear scorecardSearch userSearch' onClick={this.handleOnClear}>Clear</Button>
                                    <Col></Col>
                                </Form.Row>
                            </Form></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
FilterCountryTotals.propTypes = {
    getCountryTotals: PropTypes.func,
    teamNames: PropTypes.array,
    countryList: PropTypes.array,
    onDownloadFilter: PropTypes.func
}

export default FilterCountryTotals;