import React, { Component } from "react";
import { Modal, Button, Image, Form } from "react-bootstrap";
import "./Dataset.css";
import DatasetList from "./DatasetList";
import DatasetUploader from "./DatasetUploader";
import utils from "./../../utils";
import EndpointConstant from "./../../constants/EndpointConstant";
import { Sidebar, Header } from "..";
import MessageConstant from "./../../constants/MessageConstant";
import PopupModels from "../PopupModels/PopupModels";
import Pagination from "../Pagination/Pagination";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ProgressBar } from "primereact/progressbar";
import moment from "moment";
import MultiSelect from "@khanacademy/react-multi-select";
import { v4 as uuidV4 } from "uuid";

// import Files from './Files/Files';
// import {Redirect} from 'react-router-dom';
/**
 * A class that can return some html
 *@return {Object} .
 *@param {object} props
 *@deprecated
 */
class Dataset extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    //this.percentExtract = this.percentExtract.bind(this);
    this.state = {
      dataset: [],
      dataSort: [],
      percent: 3,
      showDatasetNameInput: false,
      datasetName: "",
      datasetType: "",
      datasetDeleteSuccess: false,
      showpagination: false,
      startDate: "",
      endDate: "",
      associatedTeam: [],
      showDataSync: false,
      extractValue: 0,
      extractSuccess: false,
      extractValidation: false,
      extractErrMsg: "",
      datasetDataAfterBioconnect: {},
      extractResponseMsg: "",
      extractResponseFlagIcon: "",
      showNameValidationMessage: false,
      CCUsers: [],
      teamUsers: [],
      ccUserWithTeam: [],
      ccUserWithName: [],
      teamOptions: [],
      teamUserData: [],
      showSamplingPopup: false,
      sampleDataset: [],
      extractCount: "",
      sampleCount: "",
      totalCount: "",
      showCountPopup: false,
      isExtractButtonDisable: false,
      caseNumber: [],
      fullCaseNumber: [],
      selectedCaseNumber: [],
      channel: [],
      teamAssignment: [],
      teamAssignmentOptions: [],
      product: [],
      productOptions: [],
      selectedChannel: [],
      country: [],
      selectedCountry: [],
      createdBy: [],
      selectedCreatedBy: [],
      showCCAdminDataSync: false,
      getCCAdminSuccess: false,
      ccAdminExtractValue: 0,
      ccadminRequest: "Processing",
      sId: "",
      countryPopup: false,
      channelPopup: false,
      sampleRequest: "Processing",
      modalMsg: "",
      samplingError: false,
      isGetSampleButtonDisable: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSortDataList = this.onSortDataList.bind(this);
    this.handleDatasetNameInputClose =
      this.handleDatasetNameInputClose.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.showDatasetNameInput = this.showDatasetNameInput.bind(this);
    this.performFileUpload = this.performFileUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.hideDatasetNameInput = this.hideDatasetNameInput.bind(this);
    this.deleteDataSet = this.deleteDataSet.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.extractDataFromBioConnect = this.extractDataFromBioConnect.bind(this);
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
    this.interval = null;
    this.handleExtractValidationClose =
      this.handleExtractValidationClose.bind(this);
    this.getDatasetListForBioconnect =
      this.getDatasetListForBioconnect.bind(this);
    this.getCCTeams = this.getCCTeams.bind(this);
    this.userSelected = this.userSelected.bind(this);
    this.compareDataset = this.compareDataset.bind(this);
    this.getSamplingDataset = this.getSamplingDataset.bind(this);
    this.handlesamplingClose = this.handlesamplingClose.bind(this);
    this.handleCountChanges = this.handleCountChanges.bind(this);
    this.handleCountPopupClose = this.handleCountPopupClose.bind(this);
    this.handleCountPopupCloseWithNo =
      this.handleCountPopupCloseWithNo.bind(this);
    this.getCCAdminTeams = this.getCCAdminTeams.bind(this);
    this.getccAdminListForBioconnect =
      this.getccAdminListForBioconnect.bind(this);
    this.hideccAdminDataSyncOnCancel =
      this.hideccAdminDataSyncOnCancel.bind(this);
    this.handleccAdminSuccessClose = this.handleccAdminSuccessClose.bind(this);
    this.getPayload = this.getPayload.bind(this);
    this.handleCountryPopupClose = this.handleCountryPopupClose.bind(this);
    this.handleChannelPopupClose = this.handleChannelPopupClose.bind(this);
    this.getSampleSets = this.getSampleSets.bind(this);
    this.getSamplingCount = this.getSamplingCount.bind(this);
  }
  componentDidMount() {
    utils.getSessionItem("role") !== "Reviewer" && this.getDatasetList();
  }
  changeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.nameValidation(e.target.value);
  }
  hideDatasetNameInput() {
    this.setState({ showDatasetNameInput: false });
  }
  downloadDataset(id) {
    //console.log("downloadDataset ++++++ " + id);

    const data = {
      datasetName: id,
    };
    utils.makeRequest(
      this,
      EndpointConstant.DOWNLOAD_DATASET,
      data,
      function (result) {
        //console.log(JSON.stringify(result));

        window.open(result.url, "Download");
      }
    );
  }
  onDrop(result) {
    result.sort(this.compare);
    this.setState({
      dataset: result,
    });
  }

  compare(a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  }

  getDatasetList() {
    //console.log("getDatasetList ++++++ ");
    utils.showLoader();
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.GET_DATASET_LIST,
      data,
      function (result) {
        utils.hideLoader();
        result.datasets.sort(self.compare);
        self.setState(
          {
            dataset: result.datasets,
            dataSort: result.datasets,
          },
          () => self.setState({ showpagination: true })
        );

        //console.log(JSON.stringify(result));
      }
    );
  }

  getDatasetListForBioconnect() {
    let data = {};
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.GET_DATASET_LIST,
      data,
      function (result) {
        result.datasets.sort(self.compare);

        self.setState(
          {
            dataset: result.datasets,
            datasetDataAfterBioconnect: result.datasets[0],
          },
          () => self.setState({ showpagination: true })
        );
        //console.log(JSON.stringify(result));
      }
    );
  }

  percentExtract(data) {
    switch (data) {
      case "minus":
        this.setState((p) => {
          //console.log(p);
          // eslint-disable-next-line
          let percent = parseInt(p.percent) - 1;
          if (percent <= 0) return (p.percent = 1);
          else return (p.percent = percent);
        });
        break;
      case "plus":
        this.setState((p) => {
          //console.log(p);
          // eslint-disable-next-line
          let percent = parseInt(p.percent) + 1;
          if (percent > 100) return (p.percent = 100);
          else return (p.percent = percent);
        });
        break;
      default:
        break;
    }
  }

  /*
   * on Sort functionality
   */
  sortDataList(prop, sortId) {
    return (a, b) => {
      if (sortId.sortId === "sort") {
        return a[prop].toString().localeCompare(b[prop].toString());
      } else if (sortId.sortId === "sort-up") {
        return b[prop].toString().localeCompare(a[prop].toString());
      } else if (sortId.sortId === "sort-down") {
        return a;
      }
    };
  }
  onSortDataList(data, e) {
    e.preventDefault();
    let sortId = e.target.querySelector("i").id;
    // let className = e.target.querySelector('i').className;
    if (sortId === "sort") {
      e.target.querySelector("i").id = "sort-up";
      e.target.querySelector("i").className = "fas fa-sort-up";
    } else if (sortId === "sort-up") {
      e.target.querySelector("i").id = "sort-down";
      e.target.querySelector("i").className = "fas fa-sort-down";
    } else if (sortId === "sort-down") {
      e.target.querySelector("i").id = "sort";
      e.target.querySelector("i").className = "fas fa-sort";
    }
    const { dataSort } = this.state;
    switch (data) {
      case "name":
        {
          let datasetName = [...dataSort].sort(
            this.sortDataList("datasetName", { sortId: sortId })
          );
          this.setState({
            dataset: datasetName,
          });
        }
        break;
      case "createdat":
        {
          let createdAt = [...dataSort].sort(
            this.sortDataList("createdAt", { sortId: sortId })
          );
          this.setState({
            dataset: createdAt,
          });
        }
        break;
      case "createdby":
        {
          let createdBy = [...dataSort].sort(
            this.sortDataList("createdBy", { sortId: sortId })
          );
          this.setState({
            dataset: createdBy,
          });
        }
        break;
      case "status":
        {
          let status = [...dataSort].sort(
            this.sortDataList("status", { sortId: sortId })
          );
          this.setState({
            dataset: status,
          });
        }
        break;
      default:
        break;
    }
  }

  showDatasetNameInput(id, files, fileType) {
    if (fileType) {
      this.setState({
        showDatasetNameInput: true,
        currentDatasetInput: "upload",
        files: files,
        fileType,
        id: id,
      });
    } else {
      this.setState({
        datasetName: files.split(".")[0],
        datasetType: files.split(".")[1],
        showDatasetNameInput: true,
        currentDatasetInput: "editName",
        datasetId: id,
      });
    }
  }

  handleDatasetNameInputClose() {
    let self = this;
    let datasetName = this.state.datasetName;
    if (datasetName.length === 0) {
      this.refs.header.showAlertDialog("Please enter dataset name");
      return;
    }

    if (this.nameValidation(datasetName)) {
      return;
    }

    let datasetNameExist = false;
    this.state.dataset.forEach((data) => {
      const datasetName = data.datasetName.split(".")[0];
      if (
        datasetName.toString().toLowerCase() ===
        self.state.datasetName.toString().toLowerCase()
      ) {
        datasetNameExist = true;
      }
    });
    if (datasetNameExist) {
      this.refs.header.showAlertDialog("Dataset name already exist!");
      return;
    }
    if (this.state.currentDatasetInput === "upload") {
      this.performFileUpload(
        this.state.files,
        this.state.fileType,
        this.state.id
      );
    } else {
      const data = {
        id: this.state.datasetId,
        name: this.state.datasetName + "." + this.state.datasetType,
      };
      this.setState({
        showDatasetNameInput: false,
      });
      utils.makeRequest(
        this,
        EndpointConstant.UPDATE_DATASET_NAME,
        data,
        function (result) {
          //console.log(JSON.stringify(result));
          self.onDrop(result.datasets);
          self.setState({
            successDialog: true,
            successMessageContent:
              "Your Dataset name has been updated successfully",
            datasetName: "",
          });
        }
      );
    }
  }

  nameValidation(datasetName) {
    let nameValid = false;
    // if(!(/(?=^.{3,63}$)(?!^(\d+\.?)+$)(^(([a-z0-9]|[a-z0-9][a-z0-9]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9]*[a-z0-9])$)/.test(datasetName))) {
    //  nameValid = true;
    //  }
    this.setState({
      showNameValidationMessage: nameValid,
    });
    return nameValid;
  }

  performFileUpload(files, fileType, id) {
    let self = this;
    this.setState({
      showDatasetNameInput: false,
    });
    utils.uploadFiles(
      this,
      files[0],
      fileType,
      id,
      function (result) {
        // self.onDrop(result.datasetList);
        if (result.hasOwnProperty("errorCode")) {
          if (
            result.errorCode === "D_OLD_401" ||
            result.errorCode === "D_IN_PROGRESS" ||
            result.errorCode === "IN_DS_404"
          ) {
            // console.log("errorCode",result.errorCode);
            //clearInterval(this.interval);
            self.hideDataSyncOnCancel();
            self.setState({
              extractValue: 0,
              extractSuccess: false,
              showDataSync: false,
            });
          }
        }
      },
      this.state.datasetName
    );
    clearInterval(this.interval);
    this.interval = null;
    this.setState({
      showDataSync: true,
      datasetDataAfterBioconnect: {},
      datasetName: "",
    });
    this.interval = setInterval(() => {
      this.getDatasetListForBioconnect();
      let val = this.state.extractValue;
      val += Math.floor(Math.random() * 10) + 1;

      if (val >= 100) {
        val = 100;
      }
      this.setState({
        extractValue: val,
      });
      if (
        this.state.datasetDataAfterBioconnect.hasOwnProperty("extractStatus")
      ) {
        if (this.state.datasetDataAfterBioconnect["status"] === "Complete") {
          this.hideDataSyncOnCancel();
          this.setState({
            extractSuccess: true,
            extractValue: 0,
            extractResponseMsg: MessageConstant.DATASET_EXTRACTED_MESSAGE,
            extractResponseFlagIcon: "success",
          });
          clearInterval(this.interval);
          this.interval = null;
        } else if (
          this.state.datasetDataAfterBioconnect["status"] === "Error"
        ) {
          this.hideDataSyncOnCancel();
          this.setState({
            extractSuccess: true,
            extractValue: 0,
            extractResponseFlagIcon: "error",
          });
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }, 5000);
  }

  closeDialogBox() {
    this.setState({
      successDialog: false,
    });
  }
  deleteDataSet(data) {
    // let self=this
    data.sort(self.compare);
    this.setState(
      {
        dataset: data,
        dataSort: data,
      },
      () => {
        this.setState({ datasetDeleteSuccess: true });
      }
    );

    //console.log(JSON.stringify(data));
  }
  handleDeleteClose() {
    this.setState({ datasetDeleteSuccess: false });
  }
  onChangePage(currentTodos) {
    this.setState({ currentTodos: currentTodos }, () => {
      this.setState({ showpagination: true });
    });
  }

  handleCountPopupClose(e) {
    this.setState({ showCountPopup: false });
    this.extractDataFromBioConnect(e);
  }
  handleCountPopupCloseWithNo() {
    this.setState({ showCountPopup: false });
  }

  handleOnChangeInput(e) {
    const { value } = e.target;
    if (value >= 0 && value <= 100) {
      this.setState({
        percent: e.target.value,
        isExtractButtonDisable: false,
      });
    }
  }
  compareDataset(a, b) {
    const valueA = a.label;
    const valueB = b.label;

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  getCCTeams() {
    let self = this;
    let payload = {
      startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
    };
    payload.sId = uuidV4();
    this.setState({
      sId: payload.sId,
      ccadminRequest: "Processing",
    });
    if (this.state.startDate !== "" && this.state.endDate !== "") {
      utils.makeRequestInBackground(
        this,
        EndpointConstant.GETCCUSERS,
        payload,
        function (result) {
          self.setState({ ccadminRequest: "Processing" });
        }
      );
    }
  }

  getCCAdminTeams(val) {
    if (this.checkDateValidation(val)) {
      clearInterval(this.interval);
      // console.log("this state",this.state);
      this.interval = null;
      this.setState({
        showCCAdminDataSync: true,
        modalMsg: "Extracting MI subcases from Bioconnect",
      });
      this.getCCTeams();
      this.interval = setInterval(() => {
        let val = this.state.ccAdminExtractValue;
        val += Math.floor(Math.random() * 5) + 1;
        if (val >= 98) {
          val = 98;
        }
        this.setState({
          ccAdminExtractValue: val,
        });
        // console.log("downloadss")
        this.state.ccadminRequest == "Processing"
          ? this.getccAdminListForBioconnect()
          : "";

        if (this.state.ccadminRequest == "Completed") {
          let self = this;
          let payload = {};
          payload.sId = this.state.sId;
          payload.ccadminRequest = "Processing";
          //payload.fileRead = true;
          // debugger
          // console.log('completed')
          utils.makeRequestInBackground(
            this,
            EndpointConstant.GETCCUSERS,
            payload,
            async function (result) {
              let users = [];
              let userDetail = [];
              let userData = [];
              let cnumber = [];
              let country = [];
              let channel = [];
              let createdby = [];
              let createdByName = [];
              let caseOwner = [];
              let productData = [];
              // console.log('resultData', result.url)
              await fetch(result.url, {
                mode: "cors",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
                method: "GET",
                body: null,
              })
                .then((response) => {
                  // console.log('response',response)
                  return response.json();
                })
                .then((result) => {
                  clearInterval(self.interval);
                  self.interval = null;
                  self.setState({ teamUserData: result.associatedteam });
                  result.associatedteam &&
                    result.associatedteam.length &&
                    result.associatedteam.forEach((teams) => {
                      users.push({ id: teams, label: teams, value: teams });
                    });
                  result.teamAssignment && result.teamAssignment.length && result.teamAssignment.forEach(teams => {
                    caseOwner.push({ id: teams, label: teams, value: teams });
                  })
                  result.product && result.product.length && result.product.forEach(product => {
                    productData.push({ id: product, label: product, value: product });
                  })
                  result.ccadmin &&
                    result.ccadmin.length &&
                    result.ccadmin.forEach((cca) => {
                      const key = Object.keys(cca);
                      const value = Object.values(cca);
                      // console.log(key, value, cca)
                      let id = key[0];
                      let name = value[0];
                      userDetail.push({ [id]: name });
                      userData.push({ id: id, label: name, value: id });
                    });
                  result.cases &&
                    result.cases.length &&
                    result.cases.forEach((subcase) => {
                      cnumber.push({
                        id: subcase,
                        label: subcase,
                        value: subcase,
                      });
                    });
                  result.channel &&
                    result.channel.length &&
                    result.channel.forEach((id) => {
                      channel.push({ id: id, label: id, value: id });
                    });
                  result.country &&
                    result.country.length &&
                    result.country.forEach((item) => {
                      country.push({ id: item, label: item, value: item });
                    });
                  result.createdBy &&
                    result.createdBy.length &&
                    result.createdBy.forEach((obj) => {
                      const key = Object.keys(obj);
                      const value = Object.values(obj);
                      let id = key[0];
                      let name = value[0];
                      createdByName.push({ [id]: name });
                      createdby.push({ id: id, label: name, value: id });
                    });
                  if (cnumber.length > 5000) {
                    self.setState({
                      caseNumber: cnumber.slice(0, 5000),
                      fullCaseNumber: cnumber,
                    });
                  } else {
                    self.setState({
                      caseNumber: cnumber,
                      fullCaseNumber: cnumber,
                    });
                  }
                  if (users.length > 0) {
                    self.setState({
                      teamOptions: users,
                      userDetail,
                      createdByName,
                      CCUsers: userData,
                      country: country,
                      channel: channel,
                      createdBy: createdby,
                      teamAssignmentOptions: caseOwner,
                      productOptions: productData
                    });
                  }
                  self.hideccAdminDataSyncOnCancel();
                  self.setState({
                    extractResponseFlagIcon: "success",
                    ccAdminExtractValue: 0,
                  });
                });
            }
          );
        }
      }, 10000);
    }
  }

  checkDateValidation(val) {
    const unixStartdate = moment(
      new Date(this.state.startDate),
      "DD/MM/YYYY hh:mm:ss"
    ).unix();
    const unixEnddate = moment(
      new Date(this.state.endDate),
      "DD/MM/YYYY hh:mm:ss"
    ).unix();
    let dd, mm, yyyy, dd1, mm1, yyyy1;
    if (this.state.startDate !== "") {
      dd = new Date(this.state.startDate).getDate();
      mm = new Date(this.state.startDate).getMonth() + 1;
      let dateValue = "";
      if (typeof this.state.startDate == "object") {
        dateValue = moment(new Date(this.state.startDate))
          .format("DD/MM/Y")
          .split("/");
      } else {
        dateValue = this.state.startDate.split("/");
      }
      yyyy = dateValue[2];
    }
    if (this.state.endDate !== "") {
      dd1 = new Date(this.state.endDate).getDate();
      mm1 = new Date(this.state.endDate).getMonth() + 1;
      let dateValue = "";
      if (typeof this.state.endDate == "object") {
        dateValue = moment(new Date(this.state.endDate))
          .format("DD/MM/Y")
          .split("/");
      } else {
        dateValue = this.state.endDate.split("/");
      }

      yyyy1 = dateValue[2];
    }

    let sDate = this.state.startDate;
    let eDate = this.state.endDate;
    if (!sDate || unixStartdate == 0 || unixEnddate == 0 || !eDate) {
      // console.log('here')
      return false;
    } else if (
      this.state.startDate &&
      (dd.toString().length < 1 ||
        mm.toString().length < 1 ||
        yyyy > 2100 ||
        yyyy < 2000 ||
        isNaN(yyyy))
    ) {
      // console.log('startdate', dd,  mm,  yyyy < 2100 && yyyy > 2000)
      return false;
    } else if (
      (this.state.endDate &&
        (dd1.toString().length < 1 ||
          mm1.toString().length < 1 ||
          yyyy1 > 2100 ||
          yyyy1 < 2000)) ||
      isNaN(yyyy1)
    ) {
      // console.log('enddate', dd1, mm1, yyyy1 < 2100 && yyyy1 > 2000)
      return false;
    } else if (
      this.state.startDate !== "" &&
      this.state.endDate !== "" &&
      (isNaN(unixEnddate) || isNaN(unixStartdate))
    ) {
      this.setState({
        extractValidation: true,
        extractErrMsg: "Enter valid date",
      });
      return false;
    } else if (unixStartdate && unixEnddate && unixStartdate > unixEnddate) {
      // console.log("start date should not greater than end date");
      this.setState({
        extractValidation: true,
        extractErrMsg: "Start date should not be greater than End date",
        startDate: val == 1 ? "" : sDate,
        endDate: val == 2 ? "" : eDate,
      });
      return false;
    } else if (unixStartdate && unixEnddate && unixStartdate < unixEnddate) {
      let differenceInTime = unixEnddate - unixStartdate;
      let differenceInDays = Math.ceil(differenceInTime / (3600 * 24));
      if (differenceInDays > 365) {
        this.setState({
          extractValidation: true,
          extractErrMsg:
            "The selected period should not be greater than one year",
          startDate: val == 1 ? "" : sDate,
          endDate: val == 2 ? "" : eDate,
        });
        return false;
      }
    }
    // console.log('true', dd, dd.toString().length < 1, mm,mm.toString().length < 1, parseInt(yyyy),  parseInt(yyyy) > 2000 || parseInt(yyyy) < 2100  )
    return true;
  }

  extractDataFromBioConnect(event) {
    event.preventDefault();
    const {
      associatedTeam,
      teamUsers,
      selectedCaseNumber,
      selectedChannel,
      selectedCountry,
      selectedCreatedBy,
      teamAssignment,
      product
    } = this.state;
    // console.log("extract Data from bio connect");
    if (this.state.startDate === "" || this.state.endDate === "") {
      // console.log("Invalid date");
      this.setState({
        extractValidation: true,
        extractErrMsg: "Start date or End date should not be empty",
      });
      //console.log("unix date",moment(new Date(this.state.startDate), 'DD/MM/YYYY hh:mm:ss').unix());
    } else if (
      !associatedTeam.length &&
      !teamUsers.length &&
      !selectedCaseNumber.length &&
      !selectedChannel.length &&
      !selectedCreatedBy.length &&
      !selectedCountry.length &&
      !teamAssignment.length &&
      !product.length
    ) {
      this.setState({
        isExtractButtonDisable: true,
        isGetSampleButtonDisable: true,
        extractValidation: true,
        extractErrMsg:
          "Please select atleast one record from any of Created by Team, Call Center Agent, Master Case Number, Channel, Product, Team Assignment, Country or Created By for sampling.",
      });
    } else {
      let maxCount = 0;
      if (associatedTeam.length > 0 && associatedTeam.length > maxCount) {
        maxCount = associatedTeam.length;
      }
      if (teamUsers.length > 0 && teamUsers.length > maxCount) {
        maxCount = teamUsers.length;
      }
      if (
        selectedCaseNumber.length > 0 &&
        selectedCaseNumber.length > maxCount
      ) {
        maxCount = selectedCaseNumber.length;
      }
      if (selectedChannel.length > 0 && selectedChannel.length > maxCount) {
        maxCount = selectedChannel.length;
      }
      if (selectedCountry.length > 0 && selectedCountry.length > maxCount) {
        maxCount = selectedCountry.length;
      }
      if (selectedCreatedBy.length > 0 && selectedCreatedBy.length > maxCount) {
        maxCount = selectedCreatedBy.length;
      }
      if (teamAssignment.length > 0 && teamAssignment.length > maxCount) {
        maxCount = teamAssignment.length;
      }
      if (product.length > 0 && product.length > maxCount) {
        maxCount = product.length;
      }
      // console.log('maxCount',maxCount)
      // console.log('this.state.teamUsers.length', this.state.teamUsers.length, this.state.associatedTeam.length, maxCount)
      const unixStartdate = moment(
        new Date(this.state.startDate),
        "DD/MM/YYYY hh:mm:ss"
      ).unix();
      const unixEnddate = moment(
        new Date(this.state.endDate),
        "DD/MM/YYYY hh:mm:ss"
      ).unix();
      //  console.log("unix date",unixStartdate,"-----", unixEnddate);
      if (unixStartdate > unixEnddate) {
        //  console.log("start date should not greater than end date");
        this.setState({
          extractValidation: true,
          extractErrMsg: "Start date should not be greater than End date",
        });
      } else if (
        (this.state.extractCount && !/^\d+$/.test(this.state.extractCount)) ||
        parseInt(this.state.extractCount) <= 0
      ) {
        this.setState({
          extractValidation: true,
          extractErrMsg: "Sample count should be a number greater than 0",
        });
      } else if (
        this.state.extractCount &&
        parseInt(this.state.extractCount) > this.state.totalCount
      ) {
        this.setState({
          extractValidation: true,
          extractErrMsg: `Sample count should be below ${this.state.totalCount} `,
        });
      } else if (
        this.state.extractCount &&
        parseInt(this.state.extractCount) < maxCount &&
        !this.state.showCountPopup
      ) {
        this.setState({ showCountPopup: true });
      } else {
        let startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        let payload = {
          sId: this.state.sId,
          startDate: startDate,
          endDate: endDate,
          extractPercentage: this.state.percent,
        };
        if (this.state.extractCount != "") {
          payload.extractCount = this.state.extractCount;
          payload.extractPercentage =
            (parseInt(this.state.extractCount) /
              parseInt(this.state.totalCount)) *
            100;
        }
        this.setState({ isExtractButtonDisable: true });
        let self = this;
        const payloadObj = this.getPayload();
        payload = Object.assign(payload, payloadObj);
        //  console.log("Payload",payload);
        utils.makeRequestInBackground(
          this,
          EndpointConstant.BIOCONNECT_EXTRACT,
          payload,
          function (result) {
            // console.log("after bioconnect call",JSON.stringify(result));
            if (result.hasOwnProperty("errorCode") || result.error || !result) {
              if (
                result.errorCode === "D_OLD_401" ||
                result.errorCode === "D_IN_PROGRESS" ||
                !result
              ) {
                // console.log("errorCode",result.errorCode);
                //clearInterval(this.interval);
                self.hideDataSyncOnCancel();
                self.setState({
                  extractValue: 0,
                  isExtractButtonDisable: false,
                });
              }
              if (result.error === "404") {
                self.setState({
                  extractValidation: true,
                  extractErrMsg:
                    "Data not available for the provided combination.",
                });
                self.hideDataSyncOnCancel();
              }
            } else {
              // console.log("results",result);
              if (result.data.length === 0) {
                self.setState({
                  extractResponseMsg: result.message,
                });
              }
            }
          }
        );
        clearInterval(this.interval);
        // console.log("this state",this.state);
        this.interval = null;
        this.setState({
          showDataSync: true,
        });
        this.interval = setInterval(() => {
          this.getDatasetListForBioconnect();
          let val = this.state.extractValue;
          val += Math.floor(Math.random() * 5) + 1;

          if (val >= 100) {
            val = 100;
          }
          if (
            this.state.datasetDataAfterBioconnect.hasOwnProperty(
              "extractStatus"
            )
          ) {
            if (
              (this.state.datasetDataAfterBioconnect["extractStatus"] ===
                "Complete" &&
                this.state.datasetDataAfterBioconnect["status"] ===
                  "Processing") ||
              (this.state.datasetDataAfterBioconnect["extractStatus"] ===
                "Complete" &&
                this.state.datasetDataAfterBioconnect["status"] === "Complete")
            ) {
              this.hideDataSyncOnCancel();
              this.setState({
                extractSuccess: true,
                extractValue: 0,
                extractResponseMsg: MessageConstant.DATASET_EXTRACTED_MESSAGE,
                extractResponseFlagIcon: "success",
              });
              clearInterval(this.interval);
              this.interval = null;
            } else if (
              this.state.datasetDataAfterBioconnect["extractStatus"] ===
                "Complete" &&
              this.state.datasetDataAfterBioconnect["status"] === "Error"
            ) {
              this.hideDataSyncOnCancel();
              this.setState({
                extractSuccess: true,
                extractValue: 0,
                extractResponseFlagIcon: "error",
                isExtractButtonDisable: false,
              });
              clearInterval(this.interval);
              this.interval = null;
            } else if (
              this.state.datasetDataAfterBioconnect["extractStatus"] ===
                "Processing" &&
              val === 100
            ) {
              this.hideDataSyncOnCancel();
              this.setState({
                extractSuccess: true,
                extractValue: 0,
                extractResponseMsg:
                  "Dataset extract is inprogress, will send you email once completed",
                extractResponseFlagIcon: "success",
                isExtractButtonDisable: false,
              });
              clearInterval(this.interval);
              this.interval = null;
            }
          }
          this.setState({
            extractValue: val,
          });
        }, 10000);
      }
    }
  }

  hideDataSyncOnCancel(event) {
    //event.preventDefault();
    this.setState({
      showDataSync: false,
      extractValue: 0,
    });
    clearInterval(this.interval);
    this.interval = null;
  }
  handleExtractSuccessClose() {
    this.setState({
      extractSuccess: false,
      percent: 3,
      extractCount: "",
      sampleCount: "",
      totalCount: "",
      teamUsers: [],
      associatedTeam: [],
      selectedCaseNumber: [],
      selectedChannel: [],
      selectedCountry: [],
      selectedCreatedBy: [],
      teamAssignment: [],
      product: [],
      caseNumber: [],
      channel: [],
      createdBy: [],
      country: [],
      teamOptions: [],
      CCUsers: [],
      startDate: "",
      endDate: "",
    });
  }
  handleExtractValidationClose() {
    this.setState({
      extractValidation: false,
      extractErrMsg: "",
    });
  }
  handleCountryPopupClose() {
    this.setState({
      countryPopup: false,
    });
  }
  handleChannelPopupClose() {
    this.setState({
      channelPopup: false,
    });
  }
  userSelected(e) {
    let ef = e;
    // console.log(e, 'e');
  }

  getPayload() {
    const {
      associatedTeam,
      teamUsers,
      userDetail,
      selectedCaseNumber,
      selectedChannel,
      selectedCountry,
      selectedCreatedBy,
      createdByName,
      teamAssignment,
      product
    } = this.state;
    let payload = {};
    if (teamUsers.length) {
      payload.callCenterAdmin = teamUsers;
      let users = [];
      teamUsers.forEach((cca) => {
        userDetail.forEach((user) => {
          let key = Object.keys(user);
          if (key[0] == cca) {
            users.push(user);
          }
        });
      });
      payload.users = users;
    }
    if (associatedTeam.length) {
      payload.associatedTeam = associatedTeam;
    }
    if (selectedCaseNumber.length) {
      payload.caseNumber = selectedCaseNumber;
    }
    if (selectedCountry.length) {
      payload.country = selectedCountry;
    }
    if (selectedChannel.length) {
      payload.channel = selectedChannel;
    }
    if (teamAssignment.length) {
      payload.teamAssignment = teamAssignment
    }
    if (product.length) {
      payload.product = product
    }
    if (selectedCreatedBy.length) {
      let createdByNames = [];
      payload.createdBy = selectedCreatedBy;
      selectedCreatedBy.forEach((cca) => {
        createdByName.forEach((user) => {
          let key = Object.keys(user);
          if (key[0] == cca) {
            createdByNames.push(user);
          }
        });
      });
      payload.createdByName = createdByNames;
    }

    return payload;
  }
  getSampleSets(e) {
    let self = this;
    let payload = {
      sId: this.state.sId,
      sampleRequest: "Processing",
    };
    utils.makeRequestInBackground(
      this,
      EndpointConstant.GET_SAMPLE_SETS,
      payload,
      function (result) {
        // console.log('result',result)
        if (!result.error) {
          let status =
            result.sample && result.sample.url ? "Completed" : "Processing";
          self.setState({
            sampleRequest: status,
          });
        }
      }
    );
  }
  getSamplingCount(e) {
    clearInterval(this.interval);
    // console.log("this state",this.state);
    this.interval = null;
    this.interval = setInterval(() => {
      let val = this.state.ccAdminExtractValue;
      val += Math.floor(Math.random() * 5) + 1;
      if (val >= 98) {
        val = 98;
      }
      this.setState({
        ccAdminExtractValue: val,
      });
      this.state.sampleRequest == "Processing" ? this.getSampleSets(e) : "";
      //console.log("sampleRequest", this.state.sampleRequest);
      if (
        this.state.sampleRequest == "Completed" &&
        !this.state.samplingError
      ) {
        const self = this;
        let payload = {
          sId: this.state.sId,
          sampleRequest: "Processing",
        };
        utils.makeRequest(
          this,
          EndpointConstant.GET_SAMPLE_SETS,
          payload,
          async function (result) {
            let url = result.sample.url;
            // console.log('result>>url',url)
            url && url.length && clearInterval(self.interval);
            await fetch(url, {
              mode: "cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
              method: "GET",
              body: null,
            })
              .then((response) => response.json())
              .then((sample) => {
                if (sample) {
                  // console.log("after bioconnect call",JSON.stringify(result));
                  // console.log("results",sample);
                  // if(result.sample) {
                  let count = 0;
                  let totalRecCount = 0;
                  let hasZeroSampleCount = false;
                  if (sample.length) {
                    sample.forEach((val) => {
                      count = val.sampleCount + count;
                      totalRecCount = val.totalCount + totalRecCount;
                    });
                  }
                  if (count == 0) {
                    hasZeroSampleCount = true;
                  }
                  self.setState({
                    hasZeroSampleCount: hasZeroSampleCount,
                    sampleCount: count,
                    totalCount: totalRecCount,
                    sampleRequest: "Processing",
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                // console.log("Testing 12345 Error");
              });
            self.setState({
              ccAdminExtractValue: 0,
            });
            self.hideccAdminDataSyncOnCancel();
          }
        );
        this.setState({
          showSamplingPopup: !this.state.showSamplingPopup,
        });
      } else if (this.state.samplingError) {
        clearInterval(this.interval);
      }
    }, 10000);
  }
  getSamplingDataset(e) {
    const self = this;
    if (this.checkValidation(e)) {
      this.setState({
        showCCAdminDataSync: true,
        modalMsg: "GET SAMPLE COUNT",
      });
      let startDate = moment(this.state.startDate).format("YYYY-MM-DD");
      let endDate = moment(this.state.endDate).format("YYYY-MM-DD");
      let payload = {
        sId: this.state.sId,
        startDate: startDate,
        endDate: endDate,
        extractPercentage: this.state.percent,
      };
      const payloadObj = this.getPayload();
      payload = Object.assign(payload, payloadObj);
      utils.makeRequestInBackground(
        this,
        EndpointConstant.GET_SAMPLE_SETS,
        payload,
        function (result) {
          if (result.hasOwnProperty("errorCode") || result.error) {
            self.hideccAdminDataSyncOnCancel();
            if (
              result.errorCode === "D_OLD_401" ||
              result.errorCode === "D_IN_PROGRESS"
            ) {
              self.hideDataSyncOnCancel();
            }
            if (result.error === "404") {
              self.setState({
                extractValidation: true,
                extractErrMsg:
                  "Data not available for the provided combination.",
              });
              self.hideDataSyncOnCancel();
            }
            self.setState({
              sampleRequest: "Completed",
              samplingError: true,
            });
          } else {
            self.setState({
              sampleRequest: "Processing",
              samplingError: false,
            });
          }
        }
      );
      this.getSamplingCount(e);
    }
  }

  handleCountChanges(e) {
    this.setState({
      extractCount: e.target.value,
      isExtractButtonDisable: false,
      //  sampleCount: e.target.value
    });
  }

  checkValidation(event) {
    event.preventDefault();
    const {
      associatedTeam,
      teamUsers,
      selectedCaseNumber,
      selectedChannel,
      selectedCountry,
      selectedCreatedBy,
      teamAssignment,
      product
    } = this.state;
    if (this.state.startDate === "" || this.state.endDate === "") {
      // console.log("Invalid date");
      this.setState({
        extractValidation: true,
        extractErrMsg: "Start date or End date should not be empty",
      });
      //console.log("unix date",moment(new Date(this.state.startDate), 'DD/MM/YYYY hh:mm:ss').unix());
    } else if (
      !associatedTeam.length &&
      !teamUsers.length &&
      !selectedCaseNumber.length &&
      !selectedChannel.length &&
      !selectedCreatedBy.length &&
      !selectedCountry.length &&
      !teamAssignment.length &&
      !product.length
    ) {
      this.setState({
        isGetSampleButtonDisable: true,
        isExtractButtonDisable: true,
        extractValidation: true,
        extractErrMsg:
          "Please select atleast one record from any of Created by Team, Call Center Agent, Master Case Number, Channel, Product, Team Assignment, Country or Created By for sampling.",
      });
      return false;
    } else {
      const unixStartdate = moment(
        new Date(this.state.startDate),
        "DD/MM/YYYY hh:mm:ss"
      ).unix();
      const unixEnddate = moment(
        new Date(this.state.endDate),
        "DD/MM/YYYY hh:mm:ss"
      ).unix();
      //  console.log("unix date",unixStartdate,"-----", unixEnddate);
      //   if(this.state.associatedTeam.length === 0){
      //     this.setState({
      //       extractValidation: true,
      //       extractErrMsg: 'Created by Team should not be empty'
      //     });
      //  }else
      if (unixStartdate > unixEnddate) {
        //  console.log("start date should not greater than end date");
        this.setState({
          extractValidation: true,
          extractErrMsg: "Start date should not be greater than End date",
        });
      } else {
        return true;
      }
      return false;
    }
  }

  handlesamplingClose() {
    this.setState({ showSamplingPopup: false });
  }

  getccAdminListForBioconnect() {
    // console.log("downn", this.state.sId);
    let data = {
      ccadminRequest: this.state.ccadminRequest,
      sId: this.state.sId,
    };
    let self = this;
    utils.makeRequestInBackground(
      this,
      EndpointConstant.GETCCUSERS,
      data,
      function (result) {
        // console.log("downResulttt", result);

        if (self.state.ccadminRequest == "Processing") {
          self.setState({ ccadminRequest: result.ccadminRequest });
          // }
        }
      }
    );
  }

  hideccAdminDataSyncOnCancel() {
    // console.log("in1092")
    this.setState({
      showCCAdminDataSync: false,
      ccAdminExtractValue: 0,
    });
    clearInterval(this.interval);
    this.interval = null;
  }

  handleccAdminSuccessClose() {
    this.setState({
      getCCAdminSuccess: false,
    });
  }
  handleChange(e) {
    const { fullCaseNumber, caseNumber } = this.state;
    let value = e.target.value;
    if (value.length > 3 && fullCaseNumber.length > 5000) {
      let ccnumber = fullCaseNumber.length && fullCaseNumber.slice(0, 5000);
      let arr =
        ccnumber &&
        ccnumber.length &&
        ccnumber.filter((item) => item.id.match(value));
      let newArr = arr.concat(caseNumber);
      // console.log('newArr',arr)
      this.setState({ caseNumber: newArr });
    } else {
      if (fullCaseNumber.length > 5000) {
        this.setState({
          caseNumber: fullCaseNumber.length && fullCaseNumber.slice(0, 5000),
        });
      }
    }
  }
  render() {
    //const siteInfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const {
      teamOptions,
      channel,
      country,
      createdBy,
      CCUsers,
      caseNumber,
      modalMsg,
      teamAssignmentOptions,
      productOptions
    } = this.state;
    //const teams = [];
    if (teamOptions.length > 0) {
      teamOptions.sort(this.compareDataset);
    }
    if (channel.length > 0) {
      channel.sort(this.compareDataset);
    }
    if (country.length > 0) {
      country.sort(this.compareDataset);
    }
    if (createdBy.length > 0) {
      createdBy.sort(this.compareDataset);
    }
    if (caseNumber.length > 0) {
      caseNumber.sort(this.compareDataset);
    }
    if (CCUsers.length > 0) {
      CCUsers.sort(this.compareDataset);
    }
    if (teamAssignmentOptions.length > 0) {
      teamAssignmentOptions.sort(this.compareDataset)
    }
    if (productOptions.length > 0) {
      productOptions.sort(this.compareDataset)
    }
    // Object.keys(siteInfo.CallCenterTeam).map((team, i) => {
    //   teams.push({ key: i, value: team, text: siteInfo.CallCenterTeam[team] });
    //   return null;
    // });
    // teams.sort(this.compare);

    // teams.map((teams) => {
    //   return teamOptions.push({ id: teams, label: teams.text, value: teams.value });
    // });
    /* const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
 
     const files = acceptedFiles.map((file) => {
       return (
         <li key={file.path}>
           {file.path} - {file.size} bytes
         </li>
       );
     }); */
    //console.log("bioconnect",this.state.datasetDataAfterBioconnect);
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate());

    const startYear = new Date("01/01/2000");
    return (
      <div className="dataset-list-screen">
        <Modal
          dialogClassName="deletionModal"
          show={this.state.successDialog}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header className="confirmation-header"></Modal.Header>
          <Modal.Body className="confirmation-body">
            <Image
              src="/Images/ico-success.png"
              className="success-img"
            ></Image>
            <div className="success-styles">Success!</div>
            {this.state.successMessageContent}
          </Modal.Body>
          <Modal.Footer className="alert-confirmation-footer">
            <Button
              active
              onClick={this.closeDialogBox.bind(this)}
              className="user-deletion-cancel"
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showDatasetNameInput}
          onHide={this.hideDatasetNameInput}
          className="dataset-list-screen-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>DATA SET NAME</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="dataset-name-entry-content">
              <label>Data Set Name</label>
              <br />
              <input
                type="text"
                className="datasetname-input"
                value={this.state.datasetName}
                name="datasetName"
                onChange={this.changeHandler}
              ></input>
              {this.state.showNameValidationMessage ? (
                <div className="error-styles name-error">
                  {MessageConstant.FILENAME_VALIDATION}
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.handleDatasetNameInputClose}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Header ref={`header`} />
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="menu-content">
          <h4 className="dataset-title">Upload Files</h4>
          <div className="row file-row">
            <div className="col-md-12 drag-drop">
              <DatasetUploader
                onDrop={this.onDrop}
                self={this}
                showDatasetNameInput={this.showDatasetNameInput}
              />
              {/* <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <div className="drag-text">
                  <p>Drag &apos; n &apos; drop some files here, or click to select files upto 20MB</p>
                  </div>
                </div>  */}
            </div>
            <div className="or-circle">
              <h5 className="drag-text">Or</h5>
            </div>
            <div className="line"></div>
            {/* <div > */}
            <div className="col-md-12 extract">
              <div className="datasetStartdate">
                <Form.Label className="label-names">Start Date</Form.Label>
                <Calendar
                  className="calender-styles"
                  style={{ width: "132px" }}
                  maxDate={yesterday}
                  value={this.state.startDate}
                  onChange={(e) => {
                    this.setState(
                      { startDate: e.value, isExtractButtonDisable: false },
                      () => {
                        this.getCCAdminTeams(1);
                      }
                    );
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  disabledDates={[today]}
                  yearRange="2000:2100"
                  showIcon={true}
                  placeholder="mm/dd/yyyy"
                  minDate={startYear}
                />
              </div>
              <div className="datasetEnddate">
                <Form.Label className="label-names">End Date</Form.Label>
                <Calendar
                  className="calender-styles"
                  style={{ width: "132px" }}
                  maxDate={yesterday}
                  value={this.state.endDate}
                  onChange={(e) => {
                    this.setState(
                      { endDate: e.value, isExtractButtonDisable: false },
                      () => {
                        this.getCCAdminTeams(2);
                      }
                    );
                  }}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange="2000:2100"
                  disabledDates={[today]}
                  showIcon={true}
                  placeholder="mm/dd/yyyy"
                  minDate={startYear}
                />
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Created by Team</Form.Label>
                <MultiSelect
                  options={teamOptions}
                  disableSearch={false}
                  selected={this.state.associatedTeam}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      associatedTeam: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">
                  Call Center Agent
                </Form.Label>
                <MultiSelect
                  options={this.state.CCUsers}
                  disableSearch={false}
                  selected={this.state.teamUsers}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      teamUsers: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Master Case Number</Form.Label>
                {/* <div onChange={this.handleChange}> */}
                  <div>
                  <MultiSelect
                    options={this.state.fullCaseNumber}
                    disableSearch={false}
                    selected={this.state.selectedCaseNumber}
                    hasSelectAll={true}
                    onSelectedChanged={(selected) => {
                      this.setState({
                        selectedCaseNumber: selected,
                        isExtractButtonDisable: false,
                        isGetSampleButtonDisable: false,
                      });
                      return null;
                    }}
                    overrideStrings={{
                      selectSomeItems: "--Select--",
                      allItemsAreSelected: "All are Selected",
                      selectAll: "Select All",
                    }}
                  />
                </div>
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Channel</Form.Label>
                <MultiSelect
                  options={this.state.channel}
                  disableSearch={false}
                  selected={this.state.selectedChannel}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      selectedChannel: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
                {/* <PopupModels showPopup={this.state.channelPopup} iconFlag="error" message="Channel not available" handleClosePopup={this.handleChannelPopupClose.bind(this)} /> */}
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Product</Form.Label>
                <MultiSelect
                  options={productOptions}
                  disableSearch={false}
                  selected={this.state.product}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      product: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
              </div>
            </div>
            {/* </div>  */}
            <div className="col-md-12 ml-4">
            <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Team Assignment</Form.Label>
                <MultiSelect
                  options={teamAssignmentOptions}
                  disableSearch={false}
                  selected={this.state.teamAssignment}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      teamAssignment: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Country</Form.Label>
                <MultiSelect
                  options={this.state.country}
                  disableSearch={false}
                  selected={this.state.selectedCountry}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      selectedCountry: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
                {/* <PopupModels showPopup={this.state.countryPopup} iconFlag="error" message="Country not available" handleClosePopup={this.handleCountryPopupClose.bind(this)} /> */}
              </div>
              <div className="datasetStartdate dataset-team">
                <Form.Label className="label-names">Created By</Form.Label>
                <MultiSelect
                  options={this.state.createdBy}
                  disableSearch={false}
                  selected={this.state.selectedCreatedBy}
                  hasSelectAll={true}
                  onSelectedChanged={(selected) => {
                    this.setState({
                      selectedCreatedBy: selected,
                      isExtractButtonDisable: false,
                      isGetSampleButtonDisable: false,
                    });
                    return null;
                  }}
                  overrideStrings={{
                    selectSomeItems: "--Select--",
                    allItemsAreSelected: "All are Selected",
                    selectAll: "Select All",
                  }}
                />
              </div>
              <div className="plus-min">
                <span
                  className="plus-min-box minus"
                  onClick={() => this.percentExtract("minus")}
                >
                  -
                </span>
                {/* {<span className="plus-min-box percent">{this.state.percent}%</span> } */}
                <span className="plus-min-box percent">
                  <input
                    type="text"
                    className={
                      this.state.percent > 99
                        ? "percentStyle t-width"
                        : this.state.percent > 9
                        ? "percentStyle d-width"
                        : "percentStyle"
                    }
                    value={this.state.percent}
                    onChange={this.handleOnChangeInput}
                  />
                  %
                </span>
                <span
                  className="plus-min-box plus"
                  onClick={() => this.percentExtract("plus")}
                >
                  +
                </span>
              </div>
              {(this.state.sampleCount || this.state.hasZeroSampleCount) && (
                <div className="datasetStartdate sample-count-width">
                  <Form.Label>
                    Sample Count: {this.state.sampleCount} /{" "}
                    {this.state.totalCount}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="extractCount"
                    value={this.state.extractCount}
                    disabled={this.state.hasZeroSampleCount}
                    onChange={this.handleCountChanges}
                  />
                </div>
              )}
              <button
                className={
                  this.state.isGetSampleButtonDisable
                    ? "btn-extract btn-disabled"
                    : "btn-extract"
                }
                onClick={this.getSamplingDataset}
                disabled={this.state.isGetSampleButtonDisable}
              >
                <span className="extract-text">Get Sample Count</span>
              </button>

              <button
                className={
                  this.state.isExtractButtonDisable
                    ? "btn-extract btn-disabled"
                    : "btn-extract"
                }
                onClick={this.extractDataFromBioConnect}
                disabled={this.state.isExtractButtonDisable}
              >
                <img
                  src="/Images/ico-extract-btn.png"
                  className="extract-btn-icon"
                  alt="Amgen"
                />
                <span className="extract-text">Extract Data</span>
              </button>
            </div>
          </div>
          <DatasetList
            dataset={this.state.currentTodos}
            datasets={this.state.dataset}
            downloadDataset={this.downloadDataset}
            onSortDataList={this.onSortDataList}
            showDatasetNameInput={this.showDatasetNameInput}
            deleteDataSet={this.deleteDataSet}
          />
          {this.state.showpagination ? (
            <Pagination
              data={this.state.dataset}
              onChangePage={this.onChangePage}
            />
          ) : (
            ""
          )}
          <PopupModels
            showPopup={this.state.datasetDeleteSuccess}
            iconFlag="success"
            message={MessageConstant.DATASET_DELETED}
            handleClosePopup={this.handleDeleteClose.bind(this)}
          />
        </div>
        {/*
         * Model popup for Bio connnect Extract button clicked
         */}
        <Modal
          show={this.state.showDataSync}
          onHide={this.hideDataSyncOnCancel}
          className="dataset-extract-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>DATA SET SYNC</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              style={{
                height: "10px",
                borderRadius: "5px",
                border: "1px solid #0063C3",
                background: "#F2F2F2",
              }}
              showValue={false}
              value={this.state.extractValue}
            />
            <p className="progressMsg">Data sync is in progress...</p>
            <p className="progressMsg">{this.state.extractValue}% completed</p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button
              variant="primary"
              className="clear "
              onClick={this.hideDataSyncOnCancel}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <PopupModels
          showPopup={this.state.extractSuccess}
          iconFlag={this.state.extractResponseFlagIcon}
          message={this.state.extractResponseMsg}
          handleClosePopup={this.handleExtractSuccessClose.bind(this)}
        />

        <PopupModels
          showPopup={this.state.extractValidation}
          iconFlag="error"
          message={this.state.extractErrMsg}
          handleClosePopup={this.handleExtractValidationClose.bind(this)}
        />

        <Modal
          dialogClassName="deletionModal"
          show={this.state.showCountPopup}
          onHide={this.handleCountPopupCloseWithNo}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header className="confirmation-header">
            <Modal.Title id="example-modal-sizes-title-sm">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="confirmation-body">
            {" "}
            {MessageConstant.SAMPLE_COUNT_CONFIRMATION}
          </Modal.Body>
          <Modal.Footer className="confirmation-footer">
            <Button
              onClick={this.handleCountPopupCloseWithNo}
              className="clear"
            >
              No
            </Button>
            <Button active onClick={this.handleCountPopupClose}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* {this.state.showSamplingPopup?<SamplingDatasetList  showPopup={this.state.showSamplingPopup} samplingData={this.state.sampleDataset} handleClosePopup={this.handlesamplingClose.bind(this)}/>:''} */}

        <Modal
          show={this.state.showCCAdminDataSync}
          onHide={this.hideccAdminDataSyncOnCancel}
          className="dataset-extract-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalMsg} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              style={{
                height: "10px",
                borderRadius: "5px",
                border: "1px solid #0063C3",
                background: "#F2F2F2",
              }}
              showValue={false}
              value={this.state.ccAdminExtractValue}
            />
            <p className="progressMsg">Fetching is in progress...</p>
            <p className="progressMsg">
              {this.state.ccAdminExtractValue}% completed
            </p>
          </Modal.Body>
          <Modal.Footer className="confirm-popup-header">
            <Button
              variant="primary"
              className="clear "
              onClick={this.hideccAdminDataSyncOnCancel}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <PopupModels
          showPopup={this.state.getCCAdminSuccess}
          iconFlag={this.state.extractResponseFlagIcon}
          message={this.state.extractResponseMsg}
          handleClosePopup={this.handleccAdminSuccessClose.bind(this)}
        />
      </div>
    );
  }
}

export default Dataset;
