import React, { Component } from "react";
import "./InteractionSearch.css";
import { Accordion, Card, Button, Form, Col, Image } from "react-bootstrap";
import utils from "./../../utils";
import EndpointConstant from "./../../constants/EndpointConstant";
import CommonService from "../../commonService/CommonService";
import PropTypes from "prop-types";
import RoleConstant from "./../../constants/RoleConstant";
import MultiSelect from "@khanacademy/react-multi-select";
import RouterConstant from "../../constants/RouterConstant";
/**
 * A class that can return some html
 *@return {Object} .
 *@param {object} props
 */
// const Regex = RegExp(/[^A-Za-z-@./#&+\s]/gi);

// function InteractionSearch(props)
class InteractionSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interactionId:
        this.props.searchData && this.props.searchData.interactionId
          ? this.props.searchData.interactionId
          : "",
      subcaseNumber:
        this.props.searchData && this.props.searchData.subcaseNumber
          ? this.props.searchData.subcaseNumber
          : "",
      createdBy:
        this.props.searchData && this.props.searchData.createdBy
          ? this.props.searchData.createdBy
          : "",
      associatedTeam:
        this.props.searchData && this.props.searchData.associatedTeam
          ? this.props.searchData.associatedTeam
          : "",
      interactionOwner:
        this.props.searchData && this.props.searchData.interactionOwner
          ? this.props.searchData.interactionOwner
          : "",
      country:
        this.props.searchData && this.props.searchData.country
          ? this.props.searchData.country
          : "",
      channel:
        this.props.searchData && this.props.searchData.channel
          ? this.props.searchData.channel
          : "",
      status:
        this.props.searchData && this.props.searchData.status
          ? this.props.searchData.status
          : "",
      assignedTo:
        this.props.searchData && this.props.searchData.assignedTo
          ? this.props.searchData.assignedTo
          : "",
      interactionDetails: [],
      iconName: "icon-drop-up",
      interactions: [],
      selected:
        this.props.searchData && this.props.searchData.selected
          ? this.props.searchData.selected
          : "",
      dataSet: [],
      userDetails: [],
      LanguageSelected: [],
      assignedToRole: this.props.assignedToRole,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.iconChange = this.iconChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  /*
   * Getting all the dataset dropdown option
   * by calling dataset API for search section
   */
  getDataSet() {
    let data = {};
    let self = this;
    utils.makeRequest(
      this,
      EndpointConstant.GET_DATASET_LIST,
      data,
      function (result) {
        //console.log("get dataset", result);
        self.setState({
          dataSet: result.datasets,
        });
        self.props.datasetIds(result.datasets);
      }
    );
  }

  getUserDetails() {
    if (
      utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
      utils.getSessionItem("role") === RoleConstant.SAdmin
    ) {
      let data = {
        deleted: true,
      };
      let self = this;
      utils.makeRequest(
        this,
        EndpointConstant.GET_USER_LIST,
        data,
        function (result) {
          const userList =
            result.users && result.users.length
              ? result.users.filter((val) => val.role === RoleConstant.Admin)
              : [];
          self.setState({
            userDetails: userList,
          });
          // self.props.datasetIds(result.datasets);
        }
      );
    }
  }

  componentDidMount() {
    if (utils.getSessionItem("role") === RoleConstant.Reviewer) {
      this.props.history &&
        this.props.history.push(RouterConstant.SCORE_CARD_LINK);
    } else {
      this.getDataSet();
      this.getUserDetails();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { roleArr } = this.props;
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    //console.log(EndpointConstant.FILTER_INTERACTIONS)
    let self = this;
    let data = {
      //fortesting: true,
    };
    // console.log("Props Testing", this.props)
    // console.log("State Testing",this.state)
    // console.log("Testing 234", this);
    if (this.state.interactionId !== "") {
      data.caseNumber = this.state.interactionId;
    }
    if (this.state.subcaseNumber !== "") {
      data.subcaseNumber = this.state.subcaseNumber;
    }
    // if(this.state.createdBy!==''){
    //   data.createdBy=this.state.createdBy
    // }
    if (this.state.associatedTeam.length !== 0) {
      data.associatedTeam = this.state.associatedTeam;
    }
    if (this.state.interactionOwner !== "") {
      data.interactionOwner = this.state.interactionOwner;
    }
    if (this.state.selected.length !== 0) {
      data.datasetId = this.state.selected;
    }
    if (this.state.country.length !== 0) {
      data.interactionCountry = this.state.country;
    }
    if (this.state.channel.length !== 0) {
      data.channel = this.state.channel;
    }
    if (this.state.status.length !== 0) {
      data.status = this.state.status;
    }
    if (this.state.assignedTo.length !== 0) {
      data.assignedTo = this.state.assignedTo;
    }
    if (
      this.state.interactionId === "" &&
      this.state.subcaseNumber === "" &&
      this.state.associatedTeam.length === 0 &&
      this.state.selected.length === 0 &&
      this.state.country.length === 0 &&
      this.state.channel.length === 0 &&
      this.state.status.length === 0 &&
      this.state.assignedTo.length === 0
    ) {
      //data.lastEvaluatedKey = null;
      this.clearFilters();
    } else {
      utils.makeRequest(
        this,
        EndpointConstant.GET_INTERACTION_LIST_FROM_FILE,
        data,
        async function (result) {
          // const interactions = result.interactions;
          let interactions;
          let newInteractions;
          if (
            utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
            utils.getSessionItem("role") === RoleConstant.SAdmin ||
            utils.getSessionItem("role") === RoleConstant.Admin
          ) {
            utils.showLoader();
            await fetch(result.interactions, {
              mode: "cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
              method: "GET",
              body: null,
            })
              .then((response) => response.json())
              .then((s3ResponseJson) => {
                // console.log("s3 response Data", s3ResponseJson);
                if (utils.getSessionItem("role") === RoleConstant.Admin) {
                  newInteractions = s3ResponseJson.filter((data) => {
                    return data.createdBy === utils.getSessionItem("userId");
                  });
                } else {
                  newInteractions = s3ResponseJson;
                }
                // newInteractions = this.handleInteractions(newInteractions);
                // newInteractions =
                //   newInteractions.length &&
                //   newInteractions.map((data) => {
                //     let result = { ...data };
                //     let assignedToRole = "";
                //     let assignedKeys =
                //       result.assignedToScorecard &&
                //       Object.keys(result.assignedToScorecard);
                //     // console.log('result.assignedTo',result.assignedTo,assignedKeys && assignedKeys.length)
                //     if (assignedKeys && assignedKeys.length) {
                //       let userRoles = ["Reviewer", "Admin", "SuperAdmin"];
                //       assignedKeys.sort().forEach((assignedId, i) => {
                //         let idsArr = assignedId.split("*");
                //         if (idsArr[1] && userRoles.includes(idsArr[1])) {
                //           assignedToRole = assignedToRole
                //             ? `${assignedToRole}, ${idsArr[1]}`
                //             : idsArr[1];
                //         } else if (
                //           idsArr[1] &&
                //           idsArr[1].length > 20 &&
                //           roleArr[idsArr[1]]
                //         ) {
                //           assignedToRole = assignedToRole
                //             ? `${assignedToRole}, ${roleArr[idsArr[1]].role}`
                //             : roleArr[idsArr[1]].role;
                //         }
                //       });
                //     } else {
                //       assignedToRole = assignedToRole
                //         ? `${assignedToRole}, ${result.assignedTo}`
                //         : result.assignedTo;
                //     }
                //     result.assignedTo = assignedToRole;
                //     // console.log('assignedToRole',result.assignedTo)
                //     return result;
                //   });

                utils.hideLoader();
              })
              .catch((error) => {
                let er;
                // console.error(error);
                // console.log("Testing 12345 Error");
              });
          }

          let getFilteredData = (interactions, data) => {
            // console.log("Testing 12345 Filtering", data.hasOwnProperty('assignedTo'))
            let interactionData = interactions;

            if (data.hasOwnProperty("datasetId")) {
              interactionData = interactionData.filter((value) =>
                data.datasetId.some((val) => val == value.datasetId)
              );
            }
            if (data.hasOwnProperty("caseNumber")) {
              interactionData = interactionData.filter(
                (value) => data.caseNumber == value.interactionId
              );
            }

            if (data.hasOwnProperty("subcaseNumber")) {
              interactionData = interactionData.filter(
                (value) =>
                  data.subcaseNumber.toLowerCase() == value.subcaseNumber
              );
            }

            if (data.hasOwnProperty("associatedTeam")) {
              interactionData = interactionData.filter((value) =>
                data.associatedTeam.some((val) => val == value.associatedTeam)
              );
            }

            if (data.hasOwnProperty("interactionCountry")) {
              interactionData = interactionData.filter((value) =>
                data.interactionCountry.some(
                  (val) => val == value.interactionCountry
                )
              );
            }

            if (data.hasOwnProperty("channel")) {
              interactionData = interactionData.filter((value) =>
                data.channel.some((val) => val == value.channel)
              );
            }
            //console.log("Testing 234", interactionData.length);
            return interactionData;
          };

          // console.log('newInteractions',newInteractions)
          //let oldInteractions = [...newInteractions]
          //let givenInteractions = [...oldInteractions]
          interactions = getFilteredData(newInteractions, data);
          // console.log("Testing 234", interactions.length);
          // self.loading = false;
          // console.log("in 306:"+data);
          self.setState({ interactionDetails: interactions });
          // console.log("datad in search"+data);
          self.props.getSearchResult(interactions, data);
          self.props.setSearchData(self.state);
          // console.log(this.state,'useres');
          var searchlastEvaluatedKey = result.lastEvaluatedKey;
          // console.log(searchlastEvaluatedKey,'searchlastEvaluatedKey');
          self.props.parentCallback(searchlastEvaluatedKey);

          // setLastEvaluatedKey(lastEvaluatedKey) {
          //   this.setState({ lastEvaluatedKey: lastEvaluatedKey })
          // }
        }
      );
    }
  }

  iconChange() {
    if (this.state.iconName === "icon-drop-up") {
      this.setState({ iconName: "icon-drop-down" });
    } else {
      this.setState({ iconName: "icon-drop-up" });
    }
  }

  clearFilters() {
    let self = this;
    this.setState(
      {
        interactionId: "",
        subcaseNumber: "",
        createdBy: "",
        associatedTeam: "",
        interactionOwner: "",
        country: "",
        channel: "",
        status: "",
        assignedTo: "",
        selected: [],
        datasetName: [],
      },
      () => {
        //  this.props.getInteractionList();
        this.props.setSearchData(this.state);
        let data = {
          lastEvaluatedKey: null,
          //fortesting: true,
        };
        utils.makeRequest(
          this,
          EndpointConstant.GET_INTERACTION_LIST_FROM_FILE,
          data,
          async function (result) {
            let interactions = result.interactions;

            //let interactions;
            if (
              utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
              utils.getSessionItem("role") === RoleConstant.SAdmin ||
              utils.getSessionItem("role") === RoleConstant.Admin
            ) {
              utils.showLoader();
              await fetch(result.interactions, {
                mode: "cors",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
                method: "GET",
                body: null,
              })
                .then((response) => response.json())
                .then((s3ResponseJson) => {
                  // console.log("s3 response Data", s3ResponseJson);
                  if (utils.getSessionItem("role") === RoleConstant.Admin) {
                    interactions = s3ResponseJson.filter((data) => {
                      return data.createdBy === utils.getSessionItem("userId");
                    });
                  } else {
                    interactions = s3ResponseJson;
                  }
                  utils.hideLoader();
                })
                .catch((error) => {
                  let er;
                  // console.error(error);
                  // console.log("Testing 12345 Error");
                });
            }

            const interactionOrdered = {};
            interactions.forEach((interaction) => {
              if (
                !interactionOrdered.hasOwnProperty(interaction.interactionId)
              ) {
                interactionOrdered[interaction.interactionId] = [];
              }
              interactionOrdered[interaction.interactionId].push(interaction);
              //console.log(interaction);
            });
            if (utils.getSessionItem("role") === RoleConstant.QualityMonitor) {
              Object.keys(interactionOrdered).forEach((interactionId) => {
                let mlCompleted = true;
                interactionOrdered[interactionId].forEach((subcase) => {
                  if (
                    !subcase.hasOwnProperty("mlResult") ||
                    !subcase.mlResult
                  ) {
                    // console.log(interactionId + "subcase.mlResult ++ " + subcase.mlResult);
                    mlCompleted = false;
                  }
                });
                if (mlCompleted) {
                  delete interactionOrdered[interactionId];
                }
              });
            }
            //console.log(interactionOrdered);
            interactions = [];
            Object.keys(interactionOrdered).forEach((interactionId) => {
              interactions = interactions.concat(
                interactionOrdered[interactionId]
              );
            });
            self.loading = false;
            // interactions = interactions.concat(self.state.interactions);

            //console.log("interactions ++ " + JSON.stringify(interactions));
            var joined = self.state.interactions.concat(interactions);
            self.setState({ interactions: joined, interactionsSort: joined });
            // self.loading = false;
            if (result.hasOwnProperty("lastEvaluatedKey")) {
              self.props.setLastEvaluatedKey(result.lastEvaluatedKey);
            }
            self.setState({ interactionDetails: interactions });

            self.props.getSearchResult(interactions);
            // console.log(this.state,'useres')
          }
        );
      }
    );
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const role = utils.getSessionItem("role");
    const teams = [];
    const assigned = [];
    Object.keys(siteinfo.CallCenterTeam).map((team, i) => {
      teams.push({ key: i, value: team, text: siteinfo.CallCenterTeam[team] });
      return null;
    });
    teams.sort(this.compare);
    delete siteinfo.AssignedTo.Model;
    Object.keys(siteinfo.AssignedTo).map((role, i) => {
      assigned.push({ key: i, value: role, text: siteinfo.AssignedTo[role] });
      return null;
    });
    assigned.sort(this.compare);
    const teamOptions = [];
    // const teamOptions = teams.map((team, i) => {
    //   return (
    //     <option key={i} value={team.value}>{team.text}</option>
    //   );
    // });
    teams.map((teams) => {
      //console.log("dataset", teams)
      return teamOptions.push({
        id: teams,
        label: teams.text,
        value: teams.value,
      });
    });
    // const channelOptions = siteinfo.Channel.map((channel, i) => {
    //   return (
    //     <option key={i} value={channel}>{channel}</option>
    //   )
    // });
    const channelOptions = [];
    siteinfo.Channel.map((channel) => {
      //console.log("dataset", channel)
      return channelOptions.push({
        id: channel,
        label: channel,
        value: channel,
      });
    });
    // siteinfo.Countries.sort(this.compare);
    // console.log(siteinfo.Countries,'countrues')
    const countryOptions = [];
    // const countryOptions = siteinfo.Countries.sort().map((country, i) => {
    //   return (
    //     <option key={i} value={country}>{country}</option>
    //   )
    // })
    siteinfo.Countries.sort().map((country) => {
      //console.log("dataset", country)
      return countryOptions.push({
        id: country,
        label: country,
        value: country,
      });
    });
    // const statusOptions = CommonService.interactionStatus.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.name}</option>
    //   )
    // })
    const statusOptions = [];
    CommonService.interactionStatus.map((status) => {
      // console.log("dataset", status)
      return statusOptions.push({
        id: status,
        label: status.name,
        value: status.value,
      });
    });
    // const AssignedToOptions = assigned.map((role, i) => {
    //   return (
    //     <option key={i} value={role.value}>{role.text}</option>
    //   );
    // });
    const AssignedToOptions = [];
    const assignedToArr = [];
    // assigned.map((role) => {
    //   console.log("dataset", role)
    //   return AssignedToOptions.push({ id: role, label: role.text, value: role.value });
    // });
    if (
      utils.getSessionItem("role") === RoleConstant.SuperAdmin ||
      utils.getSessionItem("role") === RoleConstant.SAdmin
    ) {
      const userList = this.state.userDetails;

      userList.map((val) => {
        const name = `${val.firstName} ${val.lastName}`;
        const userName = `${val.firstName} ${val.lastName} (${val.role})`;
        const lowercaseName = name.toLowerCase();
        if (!assignedToArr.includes(lowercaseName)) {
          assignedToArr.push(lowercaseName);
          return AssignedToOptions.push({
            id: val.id,
            label: userName,
            value: name,
            isDeleted: !!val.deletedDate,
          });
          //  return AssignedToOptions.push({id: val.id, role: val.role, label: userName, value: name, isDeleted: !!val.deletedDate })
        }
        return;
      });
      AssignedToOptions.sort(this.compareDataset);
    }
    AssignedToOptions.unshift({ id: 2, label: "User", value: "User" });
    AssignedToOptions.unshift({ id: 1, label: "Admin", value: "Admin" });
    AssignedToOptions.unshift({
      id: 3,
      label: "SuperAdmin",
      value: "SuperAdmin",
    });
    AssignedToOptions.unshift({ id: 4, label: "Reviewer", value: "Reviewer" });
    // AssignedToOptions.sort();
    // const options1 = [
    //   {text: "One", value: "One"},
    //   {text: "Two", value:"Two"},
    //   {text: "Three", value:"Three"},
    // ];
    // const LanguageOptions = options1.map((lang,i)=>{
    //   return (
    //     <option key={i} value={lang.value}>{lang.text}</option>
    //   );

    // });
    const { dataSet } = this.state;
    const options = [];
    if (dataSet) {
      dataSet.forEach(function (dataset) {
        //console.log("dataset", dataset);
        if (dataset.status === "Complete") {
          return options.push({
            id: dataset.id,
            label: dataset.datasetName && dataset.datasetName.split(".")[0],
            value: dataset.id,
          });
        }
      });
    }
    options.sort(this.compareDataset);

    let renderOption = ({ checked, option, onClick }) => {
      const classNames = option.isDeleted ? "am-deleted-user" : "";
      return (
        <span className={`item-renderer ${classNames}`}>
          <input type="checkbox" checked={checked} onChange={onClick} />
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              borderBottomRightRadius: "2px",
              borderTopRightRadius: "2px",
              cursor: "default",
              padding: "2px 5px",
            }}
          >
            {option.label}
          </span>
        </span>
      );
    };
    // const {languageSelected} = this.state;
    // console.log("options1",options1[0].value);
    return (
      <Accordion defaultActiveKey="0" className="cardPadding">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            onClick={this.iconChange}
            className="cardBodyStyle"
          >
            <span className="search-title">SEARCH</span>
            <Image
              src="/Images/ico-drop-up.png"
              className={this.state.iconName}
            ></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBodyStyle">
              <Form onSubmit={this.handleSubmit} className="searchForm">
                <Form.Row className="mb-2">
                  {/* <Form.Group as={Col} controlId="interactionId" md="2"> */}
                  <Col>
                    <Form.Label>Master Case Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="interactionId"
                      value={this.state.interactionId}
                      onChange={(e) => {
                        this.setState({ interactionId: e.target.value });
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Label>Sub-Case Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="subcaseNumber"
                      value={this.state.subcaseNumber}
                      onChange={(e) => {
                        this.setState({ subcaseNumber: e.target.value });
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>

                  {/* <Form.Group as={Col} controlId="associatedTeam" md="2"> */}
                  <Col>
                    <Form.Label>Created by Team</Form.Label>
                    {/* <Form.Control as='select' name='associatedTeam' value={this.state.associatedTeam} onChange={e => { this.setState({ associatedTeam: e.target.value }) }}>
                      <option value="">--Select--</option>
                      {teamOptions}
                    </Form.Control> */}

                    <MultiSelect
                      options={teamOptions}
                      selected={this.state.associatedTeam}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={(selected) => {
                        // console.log("selected values", selected);
                        this.setState({ associatedTeam: selected });
                        return null;
                      }}
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All",
                      }}
                    />
                  </Col>
                  {/* </Form.Group> */}
                  {/* <Form.Group as={Col} controlId="interactionOwner" md="2"> */}
                  <Col>
                    <Form.Label>Dataset</Form.Label>

                    <MultiSelect
                      options={options}
                      selected={this.state.selected}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={(selected) => {
                        // console.log("selected values", selected);
                        this.setState({ selected: selected });
                        return null;
                      }}
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All",
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  {/* <Col> */}

                  {/* </Col> */}
                  {/* <Form.Group as={Col} controlId="country" md="2"> */}
                  <Col>
                    <Form.Label>Country</Form.Label>
                    {/* <Form.Control as='select' name='country' value={this.state.country} onChange={e => { this.setState({ country: e.target.value }) }}>
                      <option value=''>--Select--</option>
                      {countryOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={countryOptions}
                      selected={this.state.country}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={(selected) => {
                        // console.log("selected values", selected);
                        this.setState({ country: selected });
                        return null;
                      }}
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All",
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  {/* <Form.Group as={Col} controlId="channel" md="2"> */}
                  <Col>
                    <Form.Label>Channel</Form.Label>
                    {/* <Form.Control as='select' name='channel' value={this.state.channel} onChange={e => { this.setState({ channel: e.target.value }) }}>
                      <option value=''>--Select--</option>
                      {channelOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={channelOptions}
                      selected={this.state.channel}
                      disableSearch={false}
                      hasSelectAll={true}
                      onSelectedChanged={(selected) => {
                        // console.log("selected values", selected);
                        this.setState({ channel: selected });
                        return null;
                      }}
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All",
                      }}
                    />
                  </Col>
                  {/* </Form.Group> */}
                  {/* <Form.Group as={Col} controlId="status" md="2"> */}
                  {/* </Form.Row>
              
                  <Form.Row className='mb-2' > */}
                  <Col>
                    <Form.Label>Assigned To</Form.Label>

                    <MultiSelect
                      options={AssignedToOptions}
                      selected={this.state.assignedTo}
                      ItemRenderer={renderOption}
                      disableSearch={true}
                      hasSelectAll={true}
                      onSelectedChanged={(selected) => {
                        // console.log("selected values", selected);
                        this.setState({ assignedTo: selected });
                        return null;
                      }}
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All",
                      }}
                    />
                  </Col>
                  {role === RoleConstant.TranslationVendor ||
                  role === RoleConstant.CallCenterAdmin ? (
                    " "
                  ) : (
                    <Col>
                      <Form.Label>Status</Form.Label>
                      {/* <Form.Control as='select' name='status' value={this.state.status} onChange={e => { this.setState({ status: e.target.value }) }}>
                     
                      {statusOptions}
                    </Form.Control> */}
                      <MultiSelect
                        options={statusOptions}
                        selected={this.state.status}
                        disableSearch={false}
                        hasSelectAll={true}
                        onSelectedChanged={(selected) => {
                          // console.log("selected values", selected);
                          this.setState({ status: selected });
                          return null;
                        }}
                        overrideStrings={{
                          selectSomeItems: "--Select--",
                          allItemsAreSelected: "All are Selected",
                          selectAll: "Select All",
                        }}
                      />
                      {/* </Form.Group> */}
                    </Col>
                  )}

                  {/* {(role === RoleConstant.QualityMonitor || role === RoleConstant.Admin) && */}

                  {/* }  */}
                </Form.Row>
                <Button
                  variant="secondary"
                  type="submit"
                  className="interactionSearch button-color interactionSearch-marginset"
                >
                  Search
                </Button>
                <Button
                  variant="secondary"
                  className="clear interactionSearch-marginset"
                  onClick={this.clearFilters}
                >
                  Clear
                </Button>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}
InteractionSearch.propTypes = {
  getSearchResult: PropTypes.func,
  getInteractionList: PropTypes.func,
  searchData: PropTypes.object,
  setSearchData: PropTypes.func,
};
export default InteractionSearch;
